<template>
	<div id="businessviewdesigner">
	<v-container class="EDC-Container">
		<v-container class="EDC-Row breadcrumbBackground">
			<v-row class="EDC-Row">
				<v-col class="EDC-Col text-truncate" align="left" cols="11">
					
					<ul class="breadcrumb" style="display:inline-flex;padding-left:0px !important;">
						<li>{{env_name}}</li>
						<li>
							<input type="text" v-model="businessViewName" v-on:focus="oldDMName=businessViewName" v-on:blur="OnNameChange()" :readonly="isReadOnly" :size="nameTextboxSize">
						</li>
					</ul>
					<span style="color:rgba(0,0,0,0.90);font-weight:400;font-size:13px;margin-left:5px;" v-if="business_view_id">
						<span style="cursor: pointer;" @click="ShowObjDetails">
							Ver. {{object_revision}}
						</span>
					</span >
					<span v-if="!isReadOnly" style="color:rgba(0,0,0,0.54);font-weight:400;font-size:13px;margin-left:5px;">
						{{'('+savedstatus + ')'}}
					</span>

					<span v-else style="color:rgba(0,0,0,0.54);font-weight:400;font-size:13px;margin-left:5px;">
					(Readonly Mode)</span>

					<span v-if="!isReadOnly" style="padding-left:4px !important;">
						<toggle-button class="saveTypeToggle" :labels="{checked: 'Auto Save', unchecked: 'Auto Save'}" v-model="isAutoSave" style="margin-top:0px !important;margin-bottom:0px !important;border-radius:20px !important;padding-right:2px !important;" :sync="true" :width="96" :color="{checked: '#75C791', unchecked: '#859AA4', disabled: '#CCCCCC'}" @change="setUserPreferences"/>
						<!-- <vc-button itemText="Save" :xSmall='true' :disabled="!saveType"></vc-button> -->
					</span>

					<span style="font-weight:400;font-size:13px;margin-left:5px;" :style="{'color': colorCode}">{{datasource_error||redoundoMsg}}</span>
				</v-col>
				
				<v-col class="EDC-Col" cols="1">
					<v-row class="EDC-Row">
						<v-col class="EDC-Col"></v-col>
						<v-col class="EDC-Col" cols="4">
							<svgicon v-if="!validateProgress" class="svg-icon-grid svg-fill-grid" name="validate"  style="float:right;margin-top:1px;"  title="Validate Object" @click="Validatedatamapobj"></svgicon>
						</v-col>
						<v-col class="EDC-Col" cols="4">
							<v-progress-circular :width="3" v-if="validateProgress" :size="20" color="blue" indeterminate></v-progress-circular>
							<showerrorlisttemplate :bovalidationresponse="validation_response"
		        			@totalCount="errorListCount" :errorListCount="errorListCount" :showprocess="validateProgress ? 'Y':'N'" errorsource="bo" :bographdata="bographdata" @setErrorOnBO="setErrorOnBO" style="margin-top:2px !important;" :showErrorPopUp="showErrorPopUp"></showerrorlisttemplate>
						</v-col>

						<v-col class="EDC-Col" cols="4">
							<svgicon class="svg-icon-grid svg-fill-grid" name="cancel_v2"  @click="showClosePopUp()" style="float:right;"  title="Close Tab"></svgicon>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row class="EDC-Row">
				<v-col class="EDC-Col" align="left">
					<edc-graph-toolbar :redoCount="undoStack.length" :undoCount="redoStack.length" :showAutoArrange="true" @performUndoRedo="keyupHandler" @showDescription="showDescription()" @autoArrangeBusinessObject="autoArrangeBusinessObject"  @createNewVersion="createNewVersion" @onSave="toolbarSaveClicked"></edc-graph-toolbar>
				</v-col>
			</v-row>
		</v-container>
	<!-- <v-container class="EDC-Container"> -->
      <v-row class="EDC-Row">
        <v-col cols="12" class="EDC-Col">
          <v-stepper v-model="stepper" class="EDC-Stepper" non-linear >
              <v-stepper-header class="EDC-StepperHeader">
                <v-stepper-step step="1" :rules="selectTableRule" class="EDC-StepperStep" editable>
                  Table Selection
                </v-stepper-step>
                <v-divider class="EDC-StepperDivider"></v-divider>
                <v-stepper-step step="2" :rules="selectColumnRule" class="EDC-StepperStep" editable>
                  Columns Selection
                </v-stepper-step>
				 <v-divider class="EDC-StepperDivider"></v-divider>
				 <v-stepper-step step="3" :rules="previewRule" class="EDC-StepperStep" editable>
                  Preview
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content class="EDC-Stepper-Content" step="1" elevation="0">
				<v-layout raw wrap>
					<!-- <v-row class="EDC-Row">
						<v-col class="EDC-Col" cols="2"> -->
							<tablelistpanel :tableListObj="tablelistpanelproperties" @dragged-object="draggedObject" :container-height="graphHeight" @performcollapse="perform_collapse" graphFor="business_view" :readonly="isReadOnly">
							</tablelistpanel>
						<!-- </v-col>
						<v-col class="EDC-Col" cols="10"> -->
							<div style="width:80%" id="main_holder" ref="main_holder">
							<div id="main_container" ref="main_container" :style="{'height':graphHeight}" style="width:100%;">
							<edc-graph :mainPaperDimentions="paperDimentions" :newObject="newCell" @sendLinkEvent="receiveLinkEvent" @sendElementEvent="receiveElementEvent" @sendCellEvent="receiveCellEvent" @sendPaperEvent="receivePaperEvent" @sendGraphEvent="receiveGraphEvent" graphFor="business_view" :graphLoader="tableListLoader" :propChangedFor="propChangedFor" :cellToRemove="cellToRemove" :dataForGraphRedesign="dataForGraphRedesign" :validationGraphObj="validationGraphObj" :isReadOnly="isReadOnly" @loadTableDescription='loadTableDescription' :tableDescriptionList="tableDescriptionList" :autoArrangeGraph="autoArrangeGraph" :isUnderParent="true" :copyJoinJson="copyJoinJson"  @sendNonGraphEvent="pushForUndo" :cellToModify="cellToModify"></edc-graph>
							</div>
							</div>
						<!-- </v-col>
					</v-row> -->
				</v-layout>
				
				</v-stepper-content>
				<v-stepper-content step="2" elevation="0" class="EDC-Stepper-Content">
                  <v-row class="EDC-Row">
					<v-col cols="1" class="EDC-Col" align="left">
					</v-col>
                    <v-col cols="5" class="EDC-Col" style="padding-right:20px !important;">
                    <v-row class="EDC-Row">
                    	<v-col cols="12" class="EDC-Col">
                      		<edc-column-list class="EDC-VCards v-card v-sheet theme--light" :tableListLoader="tableListLoader" :columnList="columnPanelList" :hidecloslisticon="true"
                      		@dragged-object="columnDraggedObject" @performcollapse="perform_collapse" :steptablelist="steps_table_list" :readonly='readonly'  
							  style="width:500px !important;height: 450px !important;border:0px !important;float:right !important;" :datasourceList='ds_info'>
                      		</edc-column-list>
                  		</v-col>
                     </v-row>
					<v-row class="EDC-Row" style="padding-top:8px !important;">
						<v-col cols="8" class="EDC-Col" align="left">
							<!-- Selected Object: {{selectedNode.name || 'N/A'}} -->
						</v-col>
						<v-col cols="4" class="EDC-Col" align="right">
							Total Available: {{columnPanelList.length}}
						</v-col>
					</v-row>
                    </v-col>
                    <v-col cols="5" class="EDC-Col" align="left">
					<v-row class="EDC-Row">
						<v-col  class="EDC-Col" cols="12" style="padding-left:20px !important;">
							<v-card class="EDC-VCards" style="height:450px !important;">
		                         <edc-toolbar class="EDC-Custom-Toolbar" key="selected_columns_toolbar" customLabel="Selected Columns" usedFor="list" bindWithElement="selectedColumnList" @perform-serach="performSearch"></edc-toolbar>

								<v-list dense id="selectedColumnList" class="EDC-list-for-columns overflow-y-auto style-2scroll" max-height="400" data-parent-type="listItem">
									<draggable v-model="selectedColumnList" @start="startDrag($event,'list')" :options="dragOptions" :move="onMove" group="test" id="toDrag">
										<v-list-item dense selectable v-for="(item, i) in selectedColumnList" :key="i+'selctedcolumn'" class="custom-list-item" data-parent-type="listItem" :disabled="isdisabledtxt">
		                                    <v-col cols="4" class="EDC-Col" align="left" title="Column name" style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">
										        {{item.column_display_name}}
		                                    </v-col>
		                                    <v-col cols="2" class="EDC-Col" align="left" title="Table name" style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">
										        {{item.table_name}}
		                                    </v-col>
											<v-col cols="3" class="EDC-Col" align="left" title="Datasource name" style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;padding-right:8px !important;">
										        {{getDatasourceName(item.datasource_index)}}
		                                    </v-col>
		                                    <v-col cols="2" class="EDC-Col column-alias" align="left">
										        <v-text-field v-model="item.col_alias" dense hide-details title="Column alias" @blur="getUniqueAlias(item.col_alias,i)" @change="ChangeSaveStatus()"></v-text-field>
		                                    </v-col>
		                                    <v-col cols="1" class="EDC-Col" align="center">
										        <svgicon class="svg-icon-mini svg-fill-mini" name="delete_v2"  :original="true" title="Remove column" @click="removeSelectedItem(i)"></svgicon>
		                                    </v-col>
										</v-list-item>
									</draggable>
								</v-list>
							</v-card>
						</v-col>
					</v-row>
					<v-row class="EDC-Row">
						<v-col cols="4" class="EDC-Col" align="left" style="padding-left:20px !important;padding-top:8px !important;">
								<v-checkbox class="customCheckbox" v-model="showDistinctRecords" label="Show Distinct Row" hide-details dense :disabled="isdisabledtxt" @change="ChangeSaveStatus()"></v-checkbox>
						</v-col>
						<v-col cols="8" class="EDC-Col" align="right" style="padding-top:12px !important;">
							Total Selected Columns: {{selectedColumnList.length}}
						</v-col>
					</v-row>
					</v-col>
					<v-col cols="1" class="EDC-Col" align="left">
					</v-col>
                </v-row>
                </v-stepper-content>
				<v-stepper-content step="3" elevation="0" class="EDC-Stepper-Content">
					<v-row class="EDC-Row" style="margin-left:-12px !important;height:800px !important">
						<v-col class="EDC-Col" cols="12" >
							<edc-data-grid gridFor="dataviewer1" :dataList="tableList"></edc-data-grid>
						</v-col>
				</v-row>
				</v-stepper-content>
              </v-stepper-items>
            </v-stepper>
			</v-col>
      		</v-row> 
		<!-- </v-container> -->
		<v-flex xs12>
			<v-dialog v-model="filter_dialog" eager width="920" scrollable style="overflow-y:none" persistent>
				<v-flex>
					<v-card class="rounded-card" style="height: 325px;">
						<v-toolbar dark dense>
							<v-flex class="text-md-center">Filter for table <b>{{filtertablename}}</b>
								<v-icon class="text-lg-left" @click="closeFilter('close')" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
							</v-flex>
						</v-toolbar>
						<v-flex pa-2 xs12 style="height: 225px; overflow-y: scroll;" id="step_detail_table">
							 <new-edc-criteria :filtertablename="filtertablename" :columns_for_filter="filter_rows_list" :column_name_list="avlColumnList"
							 @savedata="saveCriteria" :new_columns_for_filter="main_filterUI" :secondaryfilter="secondary_filterUI" :readonly='isReadOnly'></new-edc-criteria>
						</v-flex>
						<vc-button outline itemText="Save"  @click.native="closeFilter('save')" :disabled="isReadOnly"> 
                		</vc-button>
					</v-card>
				</v-flex>
			</v-dialog>
			<v-dialog v-model="link_dialog" width="900" persistent>
				<v-flex>
					<v-card class="rounded-card">
						<v-toolbar class="EDC-Toolbar" dense elevation="1" style="border-radius:0px !important;">
							<v-toolbar-title class="EDC-ToolbarTitle">Relationship</v-toolbar-title>

      						<v-spacer></v-spacer>
							<svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="close_join_dialog(true)" title="Close"></svgicon>
						</v-toolbar>
							<v-form ref="form2" @submit.prevent="close_join_dialog()">
								<v-container class="EDC-Container EDC-ContainerPadding">
									<v-row class="EDC-Row">
										<v-col class="EDC-Col" cols="3" align="left">
											<label class="v-label" style="margin-top: 3%;text-align: left;">Relationship Type</label>
										</v-col>
										<v-col class="EDC-Col" cols="1" v-for="n in link_relationshiplist" :key="n.id" align="left">
											<v-radio-group name="relationshipSelection" v-model="link_relationship" row hide-details>
												<v-radio :color="checkboxColor" :label="n.name" :value="n.id"
												 class="radioClass" :disabled="isReadOnly"></v-radio>
											</v-radio-group>
										</v-col>
									</v-row>
									<v-row class="EDC-Row" style="padding-bottom:16px !important;">
										<v-col class="EDC-Col" cols="4" align="left">
											<b>{{join_from_table}}</b>
										</v-col>
											
										<v-col class="EDC-Col" cols="3">
											<v-autocomplete :items="jtype_list" item-text="jtype_list" v-model="jtype" dense hide-details :disabled="isReadOnly"></v-autocomplete>
										</v-col>
										<v-col class="EDC-Col JoinRightSide" cols="4" align="left">
											<b>{{join_to_table}}</b>
										</v-col>
										<v-col class="EDC-Col" cols="1"></v-col>
									</v-row>

									<v-row class="EDC-Row">
										<v-col class="EDC-Col" cols="4">
											<v-autocomplete class="JoinLeftSide" :items="jfrom_table_list" v-if="jfrom_table_type==='BO'" item-text="table_description" item-value="table_name" return-object dense label="Select Table For Join"
										 	v-model="jfrom_selected_table" refs="jfrom_table_list" required clearable hide-details @input="new_jfrom_column_list=[];getColumnsForBoTable(jfrom_selected_table,jfrom_table_type,'jfrom')"  :disabled="isReadOnly || disabledTableForBOJoin()"></v-autocomplete>
										</v-col>
										<v-col class="EDC-Col" cols="3">
											
										</v-col>
										<v-col class="EDC-Col" cols="4">
											<v-autocomplete class="JoinRightSide" :items="jto_table_list" v-if="jto_table_type==='BO'" item-text="table_description" item-value="table_name" return-object dense label="Select Table For Join"
										 v-model="jto_selected_table" refs="jto_table_list" required clearable hide-details   @input="new_jto_column_list=[];getColumnsForBoTable(jto_selected_table,jto_table_type,'jto')" :disabled="isReadOnly || disabledTableForBOJoin()"></v-autocomplete>
										</v-col>
										<v-col class="EDC-Col" cols="1"></v-col>
									</v-row>

									<v-row class="EDC-Row">
										<v-col class="EDC-Col"  cols="4">
											<v-autocomplete class="JoinLeftSide" :items="new_jfrom_column_list" item-text="description" item-value="column_name" label="Select From"
										 	rule="selectFromrule" v-model="jfrom" refs="el_jfrom" required clearable  @input="JoinAcceleration" :disabled="isReadOnly"></v-autocomplete>
										</v-col>
										<v-col class="EDC-Col" cols="3">
											<v-autocomplete :items="operators" item-text="name" item-value="id" v-model="join_opeator" label="Operator" :disabled="isReadOnly"></v-autocomplete>
										</v-col>
										<v-col class="EDC-Col" cols="4">
											<v-autocomplete class="JoinRightSide" :items="new_jto_column_list" ref="el_jto" item-text="description" item-value="column_name" label="Select To"
										 	:rule="selectTorule" v-model="jto" required clearable :disabled="isReadOnly"></v-autocomplete>
										</v-col>
										<v-col class="EDC-Col" cols="1" style="padding-top:20px !important;">
											<svgicon v-if="add_join_button" class="svg-icon-mini svg-fill-grid" name="add_v2"  :original="true" @click="add_join(add_join_button)" title="Add join" ></svgicon>
											<svgicon v-else class="svg-icon-mini svg-fill-mini" name="add_v2"  :original="true" @click="add_join(add_join_button)" title="Add join"></svgicon>
										</v-col>
									</v-row>
									<v-row class="EDC-Row" v-for="(item, index) in join_json_for_link" :key="'joinIndex'+index" style="padding-bottom:8px !important;">
										<v-col class="EDC-Col" cols="4" align="left">
											{{item.from_column_desc}}
										</v-col>
										<v-col class="EDC-Col" cols="3" align="left">{{changeText[item.operator]}}</v-col>
										<v-col class="EDC-Col" cols="4"  align="left">
											<div class="JoinRightSide">{{item.to_column_desc}}</div>
										</v-col>
										<v-col class="EDC-Col" cols="1"  style="padding-left:2px !important">
											<svgicon  class="svg-icon-mini svg-fill-mini" name="document_edit_v2"  :original="true" @click="update_join(item.from_column, item.operator, item.to_column, index)" title="Edit join"></svgicon>
											<svgicon class="svg-icon-mini svg-fill-mini" name="delete_v2"  :original="true" @click="delete_join(index)" title="Delete join" ></svgicon>

										</v-col>
									</v-row>

									<vc-button type="submit" :disabled="isReadOnly" itemText="Save"></vc-button>
								</v-container>
							</v-form>
					</v-card>
				</v-flex>
			</v-dialog>
			<v-dialog v-model="descriptionPanel" width="1200" eager>
			<v-card>
					<rich-text-box style="backgroud:white" @DescriptionChange="DescriptionChange" :vueEditorObj="vueEditorObj"></rich-text-box>
					<v-card-actions>
						<v-spacer></v-spacer>
						<vc-button  itemText= "Close" @click.native="descriptionPanel = false">
			              
			            </vc-button>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-flex>
		<loading-panel :loader="loader"></loading-panel>
		<simplert :useRadius="true"
   			:useIcon="true"
   			ref="simplert">
		</simplert>
	</v-container>
	</div>
</template>
<script>
	import {mixinEdcGraph} from '../../mixins/edcGraph.js'
	import Simplert from 'vue2-simplert'
	import tablelistpanel from "./../../views/tablelistpaneltree.vue";
	import draggable from 'vuedraggable'
	import showerrorlisttemplate from './../../views/showerrorlisttemplate.vue'
	import columnlistpanel from '@/views/edcColumnListPanel.vue'
	import edctoolbar from './../../views/edctoolbar.vue'
	import edcGraph from '@/views/edcGraph/edcGraph.vue'
	import config from "../../config.json";
	import {
		JOIN_OPERATOR_ARRAY_JSON,
		JOIN_TYPE_FOR_DATAMAP,
		AGGREGATE_FUNCTIONS,
		FLITER_JOIN_OPERATOR_ARRAY_JSON,
		SERVER_ERROR
	} from "../../methods/flowcharts/constants.js";

	import {
		getTableList,
		getTablesByDVEnv,
		getTableColumns,
		getColumnsWithDescription,
		getRevisedColumnsWithDescription,
		getTablesAssociateWithBO,
		getTableDescriptions
	} from "../../methods/agent_api_call.js";


	import {
		post as postToServer
	} from "./../../methods/serverCall.js";
	import {
		get as getToServer
	} from "./../../methods/serverCall.js";
	import {
		ADD_BUSINESS_GRAPH_DATA,
		GET_BUSINESS_GRAPH_DATA,
		ALL_PUBLISHED_BUSINESS_OBJ_LIST,
		VALIDATE_DATA_MAP,
		ADD_GRAPH_DATA,
		GETENVIRONMENTDETAILS,
		GET_PROCESS_GRAPH_DATA,GET_PRIMARY_KEYS
	} from "../../data/url_constants.js";
	import dateformat from "@/views/dateformat.vue";
	import Criteria from '@/views/newcriteria.vue';
	import {
		VALUE_TO_TEXT,VALIDATION_IN_RPOCESS_MESSAGE
	} from "../../methods/flowcharts/constants.js";
	import RichTextBox from "@/views/richTextEditor/index.vue";
	import {
		CHECKBOX_COLOR,COLOR_CODE
	} from "@/data/macros.js";
	import {CLIENT_SIDE,SERVER_SIDE} from '@/data/macros.js'
	import * as edcGraphConstants from "../../methods/edcGraph/constants.js"
	import * as edcGraphCommon from "../../methods/edcGraph/commonFunctions.js"
	import * as JDEFormatterObj from "../../methods/JDEFormatter.js";
	import * as edcGraphObj from "../../methods/edcGraph/graph.js"
	import * as edcMergeObj from '../../methods/edcGraph/mergeUnmergeElements.js'

	export default {
		name:'BusinessViewDesigner',
		mixins:[mixinEdcGraph],
		components:{
			tablelistpanel,
			'rich-text-box':RichTextBox,
			Simplert,
			draggable,
			showerrorlisttemplate,
			'new-edc-criteria':Criteria,
			'edc-graph':edcGraph,
			'edc-column-list':columnlistpanel,
			'edc-toolbar':edctoolbar,
		},
		data(){
			return {
				paperDimentions:{
					'width':1600,
					'height':1600
				},
				newCell:{},
				graphFor:'business_view',
				graphData:{},
				loader:false,
				env_id: this.$session.get('selected_env').id,
				env_name: this.$session.get('selected_env').name,
				productName:this.$route.params.product_name,
				main_filterUI:[],
				secondary_filterUI:{groupList:[]},
				minimapTop:'370px',
				graphHeight:'450px',
				datasource_timer:'',
				link_relationshiplist: [{
						id: "1:1",
						name: "1:1"
					},
					{
						id: "1:M",
						name: "1:M"
					},
					{
						id: "M:1",
						name: "M:1"
					}
				],
				traversal_list: [{
						id: "DEPTH",
						name: "Depth First"
					},
					{
						id: "BREATH",
						name: "Breath First"
					}
				],
				datasource_error:'',
				descriptionPanel:false,
      			colorCode:COLOR_CODE,
				checkboxColor: CHECKBOX_COLOR,
				changeText: VALUE_TO_TEXT,
				snackbar:false,
      			snackbartext:'',
      			colorValue:'success',
      			avlColumnList: [],
				filter_dialog: false,
				errorListCount: 0,
				validateProgress: false,
				auto_increment_id: 0,
				currentTableObject: {},
				tableListLoader: false,
				business_view_id: null,
				join_keys_list: [],
				join_json_for_link: [],
				add_join_button: false,
				businessViewName: "Untitled Business View",
				newly_created_link: "",
				link_relationship: "1:1",
				table_list: [],
				jfrom_id:'',
				jto_id:'',
				connection_str: "",
				schema: "",
				db_type: "",
				default_table_list: [],
				join_from_table: "",
				join_to_table: "",
				jto_table_type:"",
				jfrom_table_type:"",
				join_from_table_id: "",
				jto_edc_id:"",
				jfrom_edc_id:"",
				jto_datasource_index:0,
				jfrom_datasource_index:0,
				join_to_table_id: "",
				jtype_list: JOIN_TYPE_FOR_DATAMAP,
				jtype: "Inner Join",
				stype: "Self Join",
				jto_column_list: [],
				new_jto_column_list:[],
				jto_column_description:[],
				jfrom_column_list: [],
				new_jfrom_column_list:[],
				jfrom_column_description:[],
				join_json: [],
				operators: JOIN_OPERATOR_ARRAY_JSON,
				filter_operators: FLITER_JOIN_OPERATOR_ARRAY_JSON,
				jto: "",
				jfrom: "",
				traverse_by:"",
				join_opeator: "EQUALS_TO",
				dbl_click_tbl_items: [],
				selectFromrule: [v => !!v || "Select from required"],
				selectTorule: [v => !!v || "Select to is required"],
				filter_rows_list: [],
				unique_identifier_dialog: false,
				dbl_click_tbl_cell_name: {},
				columns_for_unique_identifiers: [],
				uniqueIdentifierCell: {},
				uniqueIdentifierCellWrapper:{},
				filter_column_tbl_obj: {
					s_parentheses: "",
					column_name: "",
					operators: "",
					v_type1: 'Value',
        			v_type2: 'Value',
					value1: "",
					value2: "",
					e_parentheses: ""
				},
				tableList:{
					headers:[],
					itemkey:'',
					rows:[],
					total_count:0,
					hideCaption:true,
					tableLoading:true,
					selected_rows:[],
					tableDescription:'',
					sorting_type: SERVER_SIDE,
					filterType: SERVER_SIDE,
					paginationType: SERVER_SIDE,
					is_in_tab:true,
					showContextMenu:true,
					hideNonSelectedRows:true,
					hideRowTimer:null,
					hideRowDelay:3000, // kept 1 second less compare serverCallDelay
					showDescriptionAsHeader:false,
					gridFor:'dataviewer1',
					hideShowColumns:true,
					inputJson:{},
					hideFilter:true,
					editObjectDetails:{},
					hidePagination:true,
					hideRowInfo:true,
					hideItemPerPage:true,
					hideSelect:true,
					hideExport:true,
					hideAdvanceFilter:true,
					hideInlineFilter:true
		   		},
				timer: "",
				savedstatus: "edited",
				filter_json: {},
				link_filter_json:{},
				cell_id: "",
				dragged_tbl_list: [],
				business_obj_list:[],
				filtertablename: "",
				dateformat_dialog: false,
				tablelistpanelproperties: {},
				has_flattening_restriction: true,
				filter_popup_index: 0,
				datasource_timer_sec: 10000,
				is_error_occured: false,
				is_request_in_process:false,
				dragged_tbl_list_with_its_type:[],
				bo_description:'',
	            vueEditorObj:{},
	            showObjectDetails:false,
	            reqInputData:{},
	            oldDMName:"Untitled Business View",
	            validation_response:{},
	            bographdata:{},
	            acceleratorObj:{},
				isReadOnly:false,
				traverse_by_rule: [v => !!v || 'Traverse By is required.'],
				filter_for:'',
				selected_env:{},
				link_dialog:false,
				propChangedFor:{},
				cellToRemove:{},
				dataForGraphRedesign:{},
				validationGraphObj:{},
				jto_table_list:[],
				jfrom_table_list:[],
				tableDescriptionList:[],
				jfrom_selected_table:{},
				jto_selected_table:{},
				dragged_tbl_list_with_type:[],
				currentColumnObject:{},
				selectedColumnList:[],
				selectTableRule:[],
				selectColumnRule:[],
				previewRule:[],
				columnPanelList:[],
				stepper:1,
				showDistinctRecords:true,
				ds_info:[],
				steps_table_list:[], //this variable is for pass step table list to tablelist panel when user perform server search.
				autoArrangeGraph:false,
				valid:'',
				isObjectFullyLoaded:false, // call getting send to backend before object get loaded fully. 
				tableListByBusinessObject:{}, // this json variable is used to store tablelist by business object id. It will help to minimize db calls,
				isdisabledtxt:false,
				undoStack:[] ,
				redoStack:[],
				redoundoMsg:'',
				copyJoinJson:[],
				erp_code:this.$route.params.erpCode,
				erp_version:this.$route.params.erpVersion,
				nameTextboxSize:20,
				showErrorPopUp:false,
				cellToModify:{}, // this variable used in mergeUnmergeElements.js file
			}
		},
		computed:{
			table_column_list() {
				let data = this.$store.state.cacheData[
				this.env_id + "_table_column_list"];
				return data ? data : [];
			},
			dragOptions(){
		    return  {
		      animation: 0,
		      group: 'description',
		    };
		},
		},
		props:{
			savedatastatus:{
			type:String,
			default:''
			},
			iserroroccured:{
			type:Boolean,
			default:false
			},
			datasourceerror:{
			type:String,
			default:''
			},
			readonly:{
			type:Boolean,
			default:false
			}
		},
		watch: {
			join_json(newvalue) {
				this.ChangeSaveStatus();
			},
			dragged_tbl_list(newvalue) {
				this.ChangeSaveStatus();
			},
			filter_rows_list(newvalue) {
				this.ChangeSaveStatus();
			},
			graphData(newvalue){
				// alert('save')
			},
			stepper(newvalue){
			this.selectTableRule = []
			this.selectColumnRule = []
			if(newvalue!="1" && this.dragged_tbl_list_with_its_type.length === 0 || this.join_json.length != this.dragged_tbl_list_with_its_type.length - 1)
				this.selectTableRule = [() => false]
			if(newvalue != "2" && this.selectedColumnList.length === 0)
				this.selectColumnRule = [() => false]
			this.changeStepper()
			},
			businessViewName(newvalue,oldvalue){
				if(newvalue){
					this.nameTextboxSize=Math.max(20,newvalue.length>60?60:newvalue.length)
				}
			},
		},
		created() {
			this.savedstatus = "saved";
		},
		mounted(){
			var _this = this
			document.addEventListener('keyup',  _this.keyupHandler)
			if(!this.$route.params.data_map_id){
				_this.startInitialSaveTimer(5000)
			}

			$('#businessviewdesigner').on('keydown',function(event){
		      if (event.ctrlKey && (event.code === 'KeyZ'||event.code === 'KeyY'))
		          event.preventDefault()
		    });

			if(!this.$route.params.business_view_id){
				_this.startInitialSaveTimer(5000)
			}
			let env_id = this.env_id
			var client_id = _this.$session.get("client_id")
			var paper_container = document.getElementById("main_container");
			paper_container.addEventListener("drop", drop_on_paper_container);
			paper_container.addEventListener("dragover", allowDrop_on_paper_container);
			_this.graphHeight = (window.innerHeight-174)+'px';
			_this.minimapTop = (window.innerHeight-280)+'px';//60 internal minimap height + 140 top navigation height
			function drop_on_paper_container(event) {
				if(_this.isReadOnly)
					return false
				event.preventDefault();
				var table_name = _this.currentTableObject.title; //event.dataTransfer.getData("table_name");
				var table_type = _this.currentTableObject.type;
				var object_id = _this.currentTableObject.object_id;
				var table_version = _this.currentTableObject.object_version;
				var table_description = _this.currentTableObject.table_description
				var datasource_index = _this.currentTableObject.datasource_index // datasource index is used to identify given table is for primary(index will be 0) or secondary(index start from 1. This will help in the import export objects).
				var datasource_id = _this.currentTableObject.datasource_id
				_this.newCell = {
					"edc_type":'bo_table',
					"table_name":table_name,
					"table_type":table_type,
					"object_id":object_id,
					"object_version":table_version,
					"positionX":event.offsetX,
					"positionY":event.offsetY,
					"table_description":table_description,
					"datasource_index":datasource_index,
					"datasource_id":datasource_id,
					"applicable_for_REDOUNDO":true
				}
			}

			function allowDrop_on_paper_container(event) {
				event.preventDefault();
			}

			_this.env_id = env_id

		if(_this.$route.params.isReadOnly==='N')
			_this.isReadOnly = false

		if (_this.$route.params.business_view_id) {
			if (this.$session.get('copy')) {
				_this.isReadOnly = false
				_this.CopyGraph();
			}
			else {
				_this.RedesignGraph();
				if(this.$session.get('is_read_only')){
					this.isReadOnly = true

				}
				else{
				// here add code for open in read only and allow edit
				var data = {
					object_id: _this.$route.params.business_view_id,
					object_revision: _this.$route.params.object_revision,
					object_type_code: _this.$route.params.object_type_code,
					env_id: _this.$route.params.env_id,
					env_name: _this.$route.params.env_name
				};
				_this.business_view_id = _this.$route.params.business_view_id
				_this.object_revision =_this.$route.params.object_revision
				if(!_this.$session.get('create_new_version'))
					_this.checkobjectversion(data)
				else
					_this.isReadOnly = false
				}
			}
		} else {
			_this.isReadOnly = false
			_this.Get_Tables();
		}
		var colList = document.getElementById("selectedColumnList");
		colList.addEventListener("drop", drop_on_cols_list);
		colList.addEventListener("dragover", allowDrop_on_cols_list);
		function drop_on_cols_list(event){
			event.preventDefault();
        	if(!_this.currentColumnObject || !_this.currentColumnObject.column_name)
        		return
        	_this.performselection(_.cloneDeep(_this.currentColumnObject))
			_this.currentColumnObject = {}
			_this.ChangeSaveStatus()
			
		}
		function allowDrop_on_cols_list(event) {
			event.preventDefault();
		}

		_this.getDataSourcesDetails()
	},
	beforeDestroy() {
		clearInterval(this.timer);
		clearInterval(this.datasource_timer);
	},
	methods:{
		performselection(draggedObj, insertAt=-1){
			let _this = this
			let item = _this.getNewColumnForSelection(draggedObj)
			if(insertAt === -1){
				_this.selectedColumnList.push(item)
			}
			else{
				_this.selectedColumnList.splice(insertAt, 0, item)
				insertAt++
			}
			this.pushForUndo('add_selection',{"column":item})
			return true
		},
		getNewColumnForSelection(item){
			let alias_name = this.getUniqueAlias(item.column_name.trim())

			return {
				"column_name":item.column_name.trim(),
				"column_display_name":item.column_name.trim() + " "+ "-"+item.description,
				"table_name":item.table_name,
				"col_alias":alias_name,
				"edc_business_object_id":item.edc_business_object_id,
				"datasource_id":item.datasource_id,
				"datasource_index":item.datasource_index
			}
		},
		getAllMandatoryInfo(){
			// this format is required for the dataviewer
			let mandatoryInfo = {"ds_info":_.cloneDeep(this.ds_info[0])}
			mandatoryInfo['ds_info']["all_available_ds"]=_.cloneDeep(this.ds_info)
			mandatoryInfo["client_id"] = this.$session.get('client_id')
			mandatoryInfo['db_type'] = this.ds_info[0].business_data.database_type
			mandatoryInfo['env_id'] = this.$session.get('selected_env').id
			mandatoryInfo['user_id'] = this.$session.get('user_id')
			return mandatoryInfo
		},
		getUniqueAlias(alias,itemIndex){
			if(!alias)
				return ''
			let _this = this
			alias = alias.toUpperCase()
			let original_alias = alias
			_this.selectedColumnList.forEach((obj,index)=>{
			if(itemIndex === index)
				return
			if(obj.col_alias.toLowerCase() === alias.toLowerCase())
				alias = original_alias.toUpperCase()+"_"+(index+1)
			})
			if (itemIndex > -1)
				_this.selectedColumnList[itemIndex].col_alias = alias
			return alias
		},
		removeSelectedItem(item_index){
			this.pushForUndo('remove_selection',{"column":this.selectedColumnList[item_index]})
			this.selectedColumnList.splice(item_index,1)
			this.ChangeSaveStatus()
		},
		columnDraggedObject(object){
 			 this.currentColumnObject = object;
		},
		startDrag(event,source){
		// console.log(event.item.dataset)
		var _this = this
		console.log('source',event)
		_this.dragObject = {}
		if(event.item.dataset)
			Object.assign(_this.dragObject, event.item.dataset)
		this.ChangeSaveStatus();

		},
		performSearch(searchText,tagId){
			if(tagId === "objectTree"){
				this.tree_search = searchText
				return
			}
	        var columnList = document.getElementById(tagId)
	        var list_items = columnList.getElementsByClassName('custom-list-item')
	        var filter = searchText.toUpperCase()
	        for(var i = 0; i < list_items.length; i++){
	            if (list_items[i].innerText.trim().toUpperCase().indexOf(filter) > -1) 
	              list_items[i].style.display = "";
	            else
	              list_items[i].style.display = "none";
	        }
		},
		changeStepper(){
 		 if(this.stepper === "2"){
			this.columnPanelList = []
			var graphData = this.GetData()

    		for(var i= 0;i <graphData.dragged_tbl_list_with_its_type.length;i++){
	      		let is_drv_table = false
	      		let table_name = graphData.dragged_tbl_list_with_its_type[i].table_name
	      		let table_type = graphData.dragged_tbl_list_with_its_type[i].table_type
	      		let datasource_id = graphData.dragged_tbl_list_with_its_type[i].datasource_id
	      		let datasource_index = graphData.dragged_tbl_list_with_its_type[i].datasource_index
	      		this.showTableColumns(table_name,is_drv_table,table_type,true,datasource_id,datasource_index)
	   			}
			}
			if(this.stepper === "3"){
				if(this.selectColumnRule.length != 0){
					if(typeof this.selectColumnRule[0] === "function"){
						alert("Please provide proper inupt!")
						return
					}
				}
				var _this = this
				let job_data = _this.getAllMandatoryInfo()
				job_data['all_available_ds'] = job_data['ds_info'].all_available_ds
				job_data['all_available_datasources'] = job_data['ds_info'].all_available_ds
				job_data['is_column_details_required'] = true
				job_data['business_view_details'] = {"selected_object_type_code":"T",
					"selected_object_id":_this.business_view_id,
					"selected_object_version":_this.$session.get('object_version'),
					"selected_object_name":_this.businessViewName, 
					"columns_for_view":_this.selectedColumnList,
					"show_distinct_records":_this.showDistinctRecords}

				job_data['business_view_details']['is_preview_request'] = true
				job_data['is_super_admin'] = true
				job_data['business_view_details']['join_data'] = _this.join_json
				job_data['business_view_details']['show_distinct_records'] = _this.showDistinctRecords
				job_data['business_view_details']['node_filters'] = _this.getRevisedFilter(_this.filter_json)
				job_data['business_view_details']['merge_tables_detail_for_bv'] = edcMergeObj.getMergeDetails(this.graphData)
				let url = config.DATA_VIEWER_URL + '/preview_business_view_data'
				_this.loader = true
				postToServer(_this, url,job_data).then(response => {
					// This was fresh entry. So need add this to mainDataList.
					var new_mainlist_obj = _.cloneDeep(_this.tableList)
					var column_list =  response.columns_details
					// var column_list =  obj.data.columns_details.split(',')
					console.log('column_list',column_list)
					_.forEach(column_list,function(colObj){
						colObj = JDEFormatterObj.formatColumn(colObj,true,job_data.ds_info.business_data.database_type)
						})
					new_mainlist_obj['itemkey'] = "rn"
					new_mainlist_obj.total_count = 0
					if(response.data.length > 0){
						new_mainlist_obj.total_count = response.data[0].total_count;
					}

					new_mainlist_obj.headers = column_list
					_this.assignRowForcefully(new_mainlist_obj,response.data)
					_this.tableList = new_mainlist_obj
					_this.loader = false

				}).catch(error=>{
					_this.loader = false
					_this.tableList.rows = []
					_this.tableList.total_count = 0
					alert("failed to load data for grid view")
				})
			}
		},
		assignRowForcefully(gridObj,rows){
	    	let _this = this
	    	_this.$nextTick(function () {
	          gridObj.rows = rows
	        })
  		},
		onMove({ relatedContext, draggedContext }) {
	  		const relatedElement = relatedContext.element;
	  		const draggedElement = draggedContext.element;
	  		return (
	    	(!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
	    	);
		},
		showTableColumns(table_name,is_drv_table, table_type,show_filter,datasource_id,datasource_index){
	      	// show_filter variable used to load filter columns in case of user dragged the table on graph.In this case we no need to show table for selection popup we just need to fill up the filter combo box. Thats why 'var open_column_selection_popup= !show_filter' this stament used 
	      	var _this = this
	      	var open_column_selection_popup = !show_filter


	      	this.dbl_click_tbl_cell_name = table_name
	      	var kwargs = _this.getDatasourceObj(datasource_index)
			_.assign(kwargs, _this.getCommonDetailsForColumnFetch(table_name,table_type))
	      	getRevisedColumnsWithDescription(_this,kwargs).then(tableColumnResponse => {
	        	this.dbl_click_tbl_items = []
	        	// to check response is list or object we cannot use build in function because microsoft 
	        	// age might not support it. So logic is this if table response contain the 
	        	// column list key means its an object else its any array 
	        	var column_response = tableColumnResponse
	        	var all_col_description = []
	        	if(tableColumnResponse.column_list){
	          		column_response = tableColumnResponse.column_list
	        	}
	        	var substringIndex = 0
	        	if(_this.acceleratorObj.acceleration_type === 'jde_ed_e1')
	          		substringIndex = 2
	        	if(tableColumnResponse.description)
	          		all_col_description = tableColumnResponse.description

	        	for(var i=0;i<column_response.length;i++){
		          var _data_dict = {"forselection": false,
		          "forfilter":false,
		          "table_name": table_name,
		          "column_name": column_response[i],
		          "is_drv_table":is_drv_table,
				  "datasource_index":datasource_index,
		          "description":edcGraphCommon.getColDescription(substringIndex,column_response[i],all_col_description,is_drv_table), 
				  "datasource_id":datasource_id, 
				  "datasource_index":datasource_index}
		          this.dbl_click_tbl_items.push(_data_dict)
		          this.columnPanelList.push(_data_dict)
		          // add_fliter_row.push(true) ;
		        }
	      	}).catch({});
	    },
		getColumnsForBoTable(table_data,table_type,is_from){

			let _this = this
			var kwargs = {"table_column_list":_this.table_column_list, "env_id":_this.env_id,"connection_str":_this.connection_str,"schema":_this.schema, "table_name":table_data.table_name, "table_type": table_data.table_type, "need_column_db_details":true}
		
			getColumnsWithDescription(_this,kwargs).then(tableColumnResponse => {
			if(is_from === "jfrom"){
				_this.jfrom_column_list = tableColumnResponse.column_db_details;
				// this.jfrom_column_list.push(tableColumnResponse.column_list)
				_this.jfrom_column_description = tableColumnResponse.description
				_this.new_jfrom_column_list = []
				_this.createJoinColsDesc(_this.jfrom_column_list, _this.new_jfrom_column_list, _this.jfrom_column_description, false, kwargs.table_name)
				// this.new_jfrom_column_list.push(this.new_jfrom_column_list_1)
				// _this.new_jfrom_column_list = _this.new_jfrom_column_list.concat(_this.new_jfrom_column_list_1)
			}
			else{
				_this.jto_column_list = tableColumnResponse.column_db_details;
				_this.jto_column_description = tableColumnResponse.description
				_this.new_jto_column_list = []
				_this.createJoinColsDesc(_this.jto_column_list, _this.new_jto_column_list, _this.jto_column_description, false, kwargs.table_name)
			}
		})
		.catch();		
		},
		receiveLinkEvent(eventName,data){
			let _this = this
			let link = data.link
			if(eventName === 'addbusinesslink'){
			}
			else if(eventName === 'connect'){
				_this.link_dialog = true
				_this.getLinkDetails(link,data.source,data.target)
			}
			else if(eventName === 'doubleclick')
			{	
				_this.link_dialog = true
				_this.getLinkDetails(link,data.source,data.target,data.linkData)
			}
			else if(eventName === 'linkfilter'){
				_this.getLinkDetails(link,data.source,data.target)
				_this.showLinkFilterRow(link)
			}
			else if(eventName === 'remove'){
				_this.perform_link_Removed(link.id,data)
			}
		},
		receiveElementEvent(eventName,data){
			let _this = this
			let element = data.element
			if(eventName === 'add' && element.prop('edc_cell_type') === edcGraphConstants.BUSINESS_EDC_CELL_TYPE){
				let mergingDetails = data['mergingDetails']
				_this.perfromNewTableAdd(element,mergingDetails)

				if(element.prop('applicable_for_REDOUNDO')){
						let zippedElement = edcGraphObj.zipElement(element,_this.graphFor)
						zippedElement["edc_type"] = 'bo_table'
						this.pushForUndo('add_element',{"element":zippedElement})
					}

			}

			else if(eventName === 'remove'){
				_this.performElementRemove(data.element)
			}
			else if(eventName === 'click'){
				_this.showFilterRow(element);
			}else if(eventName === 'showfilter'){
				_this.showFilterRow(element,true)
			}
			else if(eventName === 'showcols'){
				
			}else if(eventName === 'clearidentifiers'){
				_this.clearAllIdentifier()
			}
			else if(eventName === 'positionChange'){
				if(data.mergingDetails)
					edcMergeObj.performMergeProcess(_this,_this.env_id,data.mergingDetails)
			}
		},
		receiveCellEvent(data){
			let _this = this

		},
		receiveGraphEvent(eventName,data){
			let _this = this
			_this.graphData = data.graphData
			if(edcGraphConstants.GRAPH_EVENTS_FOR_REDESIGN.indexOf(eventName)>-1){
				_this.ChangeSaveStatus()			
			}
			
		},
		receivePaperEvent(data){
			let _this = this

		},
		perfromNewTableAdd(element,mergingDetails){
			let _this = this
			var table_name = element.prop("table_name");
			var table_type = element.prop("table_type")
			var object_id = element.prop("object_id")
			var object_version = element.prop("object_version")
			var datasource_id = element.prop('datasource_id')
			var datasource_index = element.prop('datasource_index')
			_this.dragged_tbl_list.push(table_name);
			
			_this.dragged_tbl_list_with_its_type.push({"table_name":table_name,"table_type":table_type,'datasource_index':datasource_index,'datasource_id':datasource_id})
			
			var kwargs = _this.getDatasourceObj(datasource_index)
			_.assign(kwargs, _this.getCommonDetailsForColumnFetch(table_name,table_type))
			getRevisedColumnsWithDescription(_this,kwargs).then(response=>{
				if(mergingDetails){
						let isValidForMerge =edcMergeObj.performMergeProcess(_this,_this.env_id,mergingDetails)
					}

			});
			
		},

		checkobjectversion(record){
	        let _this = this;
	        if(record.object_version){
	            var object_revision = record.object_version
	        }else{
	            var object_revision =  record.object_revision
	        }
	        var inputJson =  {"object_id": record.object_id, "object_revision": object_revision, "env_id": this.$session.get('selected_env').id}
	        postToServer(this, config.PUBLISHER_URL + '/object_published_in_multiple_env', inputJson).then(response => {
	            if(response){
	                if(response.status == 'CREATE_NEW_VERSION'){
	                	this.isReadOnly = true
	                    this.ShowConfirm(record)
	                } else if (response.status == 'PENDING_APPROVAL'){
	                	this.isReadOnly = true
	                    alert("Object is in Approval pending state, Can not edit object")
	                } else {
	                    _this.isReadOnly = false
	                }
	            } else {
	               this.errorMsg = response
	            }
	        }).catch(ProcessDocError => {
	            if(ProcessDocError){
	                this.loader = null 
	                this.snackbar = true
	                this.colorValue = 'error'
	                this.tableList.rows = [];
	                this.tableList.total_count =0; 
	                this.snackbartext = ProcessDocError;
	            }
	            else {
	                this.snackbar = true
	                this.colorValue = 'error'
	                this.snackbartext = SERVER_ERROR;
	            }
	        });
	    },
        ShowConfirm(object){
			var _this = this
	        let confirmFn = function () {
	        _this.SendObjectForNewVerion(object)
	        }
	        let obj = {
	          title: 'Published Object',
	          message: 'This Object has been published. You can create a new version or open it in read only mode',
	          type: 'info',
	          useConfirmBtn: true,
	          onConfirm: confirmFn,
	          customCloseBtnText:'Read Only',
	          customConfirmBtnText:'New Version'
	        }
	        this.$refs.simplert.openSimplert(obj)
	    },
		closeFilterPanel(){
			alert("Hello");
		},
		deleteElement(type) {
			let _this = this;
			ContextMenuItemClick(this, "delete");
		},
		async openFilterPanel(columnList) {
			let _this = this;
			this.avlColumnList = [];
			if (columnList && columnList.column_list){
				this.avlColumnList = columnList.column_list;
				} 
			else if(columnList)
				this.avlColumnList = columnList;
			else {
				await this.jfrom_column_list.map(str => {
					let obj = _this.join_from_table + "." + str;
					_this.avlColumnList.push(_.cloneDeep(obj));
				});
				await this.jto_column_list.map(str => {
					let obj = _this.join_to_table + "." + str;
					_this.avlColumnList.push(_.cloneDeep(obj));
				});
			}
			this.filter_dialog = true;
		},
		openJoinPanel() {
			let _this = this;
			_this.link_dialog = true;
		},
		MouseOverEvent(obj, condition) {
			obj.target.style.backgroundColor = "grey";
		},
		MouseOutEvent(obj) {
			obj.target.style.backgroundColor = "white";
		},
		draggedObject(object) {
			this.currentTableObject = object;
		},
		hideDetails() {
			let _this = this;
			setTimeout(function () {
				_this.tableListLoader = false;
			}, 500);
		},
		Get_Tables() {
			var _this = this;
			var _this = this;
			if (_this.datasource_timer_sec <= 10000) _this.tableListLoader = true;
			_this.datasource_error = "Connecting to business datasource";
			clearInterval(_this.datasource_timer);
			 // dictionary parameters
			var search_types = ["table","view","alias"]
      		var kwargs = {"description_needed":true,
                    "check_in_description":false,
                    "object_type":search_types}

			getTablesByDVEnv(
				_this,{'env_id':_this.env_id,"description_needed":true,
                "check_in_description":false,
				"client_id": this.$session.get('client_id')}
			).then(tableResponse => {
				_this.datasource_error = "";
				_this.tableListLoader = false;
				_this.tablelistpanelproperties = tableResponse
				_this.acceleratorObj['acceleration_type'] = tableResponse.available_datasource_list[0].acceleration_type
			})
			.catch(errorResponse => {
				_this.tableListLoader = false;
				_this.datasource_error =
					"Enabled to connect business datasource.Retry in " +
					_this.datasource_timer_sec / 1000 +
					" seconds";
				_this.datasource_timer = setInterval(
					_this.Get_Tables,
					_this.datasource_timer_sec
				);
				_this.datasource_timer_sec = _this.datasource_timer_sec * 2;
			});
		},
		saveCriteria(columns_for_filter,main_filter,secondary_filter){
      		this.main_filterUI = main_filter
      		this.secondary_filterUI=secondary_filter 
      		let all_filters_for_table = _.cloneDeep(columns_for_filter);
      		for(let i=0;i<all_filters_for_table.length;i++)
      			all_filters_for_table[i]['table_name'] = this.filtertablename
      		this.filter_rows_list = all_filters_for_table
		},
		closeFilter(action,isFromRedoUndo=false){
			// this method is created for the show group icon properly
			if(action==='save'){
				let oldFilterForUndo = []
				if(this.filter_for === 'cell'){
			      	if(!this.filter_json[this.cell_id])
			      			this.filter_json[this.cell_id] = {}
			      		if(this.filter_json[this.cell_id]["filter_rows_list"])
			      			oldFilterForUndo = _.cloneDeep(this.filter_json[this.cell_id]["filter_rows_list"])

			      		this.filter_json[this.cell_id]["filter_rows_list"] = _.cloneDeep(this.filter_rows_list)
			      		if(this.filter_rows_list.length>0 && this.filter_rows_list[0].column_name)
				      		this.propChangedFor = {
				      			"cell_id":this.cell_id,
				      			"action":"add",
								"changed_for":'filter'
				      		}
				      	else{
				      		this.propChangedFor = {
				      			"cell_id":this.cell_id,
				      			"action":"remove",
								"changed_for":'filter'
				      		}
				      	}
		      	}
			    // send event for filter change 
			    if(!isFromRedoUndo){
			    	this.pushForUndo("filter_change",{"old_value":_.cloneDeep(this.filter_rows_list),"new_value":oldFilterForUndo,"filter_for":this.filter_for,"cell_id":this.cell_id,"link":this.zippedLink})
			    }
			}
			this.filter_dialog = false
			this.cell_id = ''
			this.link_id = ''
			if(!isFromRedoUndo){
				this.main_filterUI = []
				this.secondary_filterUI = {groupList:[]}
			}
			this.ChangeSaveStatus();
		},
		OnNameChange() {
			// code check for if user didnt specify any name to object then we need to give old name
			if(!this.businessViewName)
				this.businessViewName = this.oldDMName
			if(this.businessViewName != this.oldDMName){
				this.pushForUndo('bv_name_changed',{"old_value":this.oldDMName,"new_value":this.businessViewName})
			}
			this.ChangeSaveStatus();
			if (!this.timer) this.timer = setInterval(this.AutoSaveBusinessViewGraph, 3000);
		},
		ChangeSaveStatus() {
			// 
			this.is_error_occured = false;
			this.savedstatus = "edited";
			if (!this.timer) this.timer = setInterval(this.AutoSaveBusinessViewGraph, 3000);
		},
		getdate(index, data) {
			index = this.filter_popup_index;
			var date_data = _.cloneDeep(data);
			this.filter_rows_list[index].value = date_data["value"];
			this.filter_rows_list[index].datedetails = date_data;
			this.dateformat_dialog = false;
			this.ChangeSaveStatus();
		},
		perform_link_Removed(link_id,data) {
			this.copyJoinJson = _.cloneDeep(this.join_json)
			let relatedJoins = []
			for (var i = 0; i < this.join_json.length; i++) {
				if (Object.keys(this.join_json[i])[0] === link_id) {
					relatedJoins = this.join_json.splice(i, 1);
					break;
				}
			}
			let relatedFilters = {}
			if(this.link_filter_json[link_id]){
				relatedFilters = _.cloneDeep(this.link_filter_json[link_id])
				delete this.link_filter_json[link_id]
			}
			if(data && data.stackObj){
				data.stackObj['relatedJoins'] = relatedJoins
				data.stackObj['relatedFilters'] = relatedFilters
				this.pushForUndo(data.stackObj.user_action,data.stackObj)
			}
			this.ChangeSaveStatus();
		},
		performElementRemove(element) {
			if(!element.prop('is_embed'))
				return
			var table_to_removed = element.prop("table_name")
			var datasource_index = element.prop('datasource_index')
			var element_id = element.id;
			this.filter_rows_list = [];
			this.main_filterUI = []
			this.secondary_filterUI = {groupList:[]}
			if (this.dragged_tbl_list.includes(table_to_removed)) {
				this.dragged_tbl_list.splice(
					this.dragged_tbl_list.indexOf(table_to_removed),
					1
				);
				for (var i = 0; i< this.dragged_tbl_list_with_its_type.length; i++) {
					if(this.dragged_tbl_list_with_its_type[i]['table_name'] === table_to_removed && this.dragged_tbl_list_with_its_type[i]['datasource_index'] === datasource_index){
						this.dragged_tbl_list_with_its_type.splice(
							this.dragged_tbl_list_with_its_type.indexOf(this.dragged_tbl_list_with_its_type[i]),1);
						break;
					}
				}
				for (var i = this.selectedColumnList.length - 1; i >= 0; i--) {
        			if(this.selectedColumnList[i].table_name === table_to_removed)
          				this.selectedColumnList.splice(i,1)
      				}
			}
			delete this.filter_json[element_id];
			
			this.ChangeSaveStatus();
		},
		ZoomAction(action) {
			performZoomActions(this, action);
		},
		ContextMenuClick(action_type) {
			ContextMenuItemClick(this, action_type);
		},
		getLinkDetails(link,source,target,linkData) {
			var _this = this
			_this.newly_created_link = link
			this.zippedLink = edcGraphObj.zipLink(link,_this.graphFor)
			this.link_id = link.id;
			this.jfrom = "";
			this.jto = "";
			this.join_json_for_link = "";
			this.join_keys_list = [];
			this.join_opeator = "_eq_";
			// this.env_id = this.$session.get("selected_env").id;
			var source_id = source.id;
			var target_id = target.id;
			this.join_from_table = source.prop("table_name")
			this.jfrom_id = source.prop("object_id")
			this.jfrom_table_type = source.prop("table_type");
			this.jfrom_edc_id = source.prop("table_name")
			var from_datasource_id = source.prop('datasource_id')
			this.jfrom_datasource_index = source.prop('datasource_index')
			this.jfrom_selected_table = {}
			this.jto_selected_table = {}
			this.join_to_table = target.prop("table_name")
			this.jto_id = target.prop("object_id")
			this.jto_table_type = target.prop("table_type");
			this.jto_edc_id = target.prop("table_name")
			var to_datasource_id = target.prop('datasource_id')
			this.jto_datasource_index = target.prop('datasource_index')
			if (link.prop("labels/0/attrs/text/text"))
				this.link_relationship =
				link.prop("labels/0/attrs/text/text") +
				":" +
				link.prop("labels/1/attrs/text/text");
			// .model.label(0, { attrs: { text: { text: 'new label' } } });
			if (!this.link_relationship) 
				this.link_relationship = "1:1";

			// has_flattening_restriction default should be true.Issue no.587
			if(link.prop("has_flattening_restriction") === false)
				this.has_flattening_restriction = false
			else
				this.has_flattening_restriction = true


			this.join_from_table_id = source_id;
			this.join_to_table_id = target_id;
			
			if (this.jfrom_table_type != "BO"){
				let table_name = _this.join_from_table
				var kwargs = _this.getDatasourceObj(this.jfrom_datasource_index)
				_.assign(kwargs, _this.getCommonDetailsForColumnFetch(table_name,this.jfrom_table_type))
				getRevisedColumnsWithDescription(_this,kwargs).then(tableColumnResponse => {
					this.jfrom_column_list = tableColumnResponse.column_db_details;
					var description = tableColumnResponse.description
					this.jfrom_column_description = description
					this.new_jfrom_column_list = []
					_this.createJoinColsDesc(this.jfrom_column_list, this.new_jfrom_column_list, description, false , table_name)
				})
				.catch();
			}
			
			if (this.jto_table_type != "BO"){
				let table_name = _this.join_to_table
				var kwargs = _this.getDatasourceObj(this.jto_datasource_index)
				_.assign(kwargs, _this.getCommonDetailsForColumnFetch(table_name,this.jto_table_type))
				getRevisedColumnsWithDescription(_this,kwargs)
				.then(tableColumnResponse => {
					this.jto_column_list = tableColumnResponse.column_db_details;
					var description = tableColumnResponse.description
					this.new_jto_column_list = []
					this.jto_column_description = description
					_this.createJoinColsDesc(this.jto_column_list, this.new_jto_column_list, description, false, table_name)
				})
				.catch();
			}
			let linkJoinIndex = -1
			for (var i = 0; i < this.join_json.length; i++) {
				if (this.join_json[i][this.link_id]) {
					linkJoinIndex = i
					this.join_keys_list = this.join_json[i][this.link_id].condition;
					this.join_json_for_link = this.join_json[i][this.link_id].condition;
					this.jtype = this.join_json[i][this.link_id].type;
				}
			}
			if(linkData && linkData.isForJoin){
				_this.manageJoinsRedoUndoActions(linkData,linkJoinIndex)
				
			}
		},	
		bindTableForBOJoin(table_list,column_list,join_obj_type,type){
			let _this = this
			for (var i = 0; i < this.join_json.length; i++) {
				if (this.join_json[i][this.link_id]) {
					let selected_table = _.find(table_list,["table_name",this.join_json[i][this.link_id][type]])
					if(selected_table){
						if(type === 'jfrom')
							_this.jfrom_selected_table = selected_table
						else
							_this.jto_selected_table = selected_table
						column_list=[]
						_this.getColumnsForBoTable(selected_table,join_obj_type,type)
					}
					break;
				}
			}

		},
		disabledTableForBOJoin(){
			for (var i = 0; i < this.join_json.length; i++) {
				if (this.join_json[i][this.link_id]) {
					if (this.join_json[i][this.link_id].condition && this.join_json[i][this.link_id].condition.length)
						return true
				}
			}
			return false
		},
		createJoinColsDesc(source_list,target_list, description,is_unique_identifier, table_name){
			var substringIndex = 0
			// var table_name = this.join_from_table
        	if(this.acceleratorObj.acceleration_type === 'jde_ed_e1')
          		substringIndex = 2
			_.forEach(source_list,function(column){
				let description_text = ""
				let each_item = column['name']
				column["column_name"] = each_item
				column['is_row_editable'] = true
				if(description && description.length > 0)
				{
					if(is_unique_identifier)
						substringIndex = 0
					description_text = edcGraphCommon.getColDescription(substringIndex,each_item,description,false)
					if (description_text)
						description_text = " ("+description_text+")"
					else
						description_text = ''
				}
				if(table_name){
					column["table_name"] = table_name
					// "description":table_name + "." + each_item+""+description_text ,
					column["description"] = table_name + "." +each_item+""+description_text
				}
				else{
					column["description"] = each_item+""+description_text
				}
			target_list.push(column)	
			})
		},
		getDescriptionInCondition(column_name,join_side){
			var substringIndex = 0
	    	if(this.acceleratorObj.acceleration_type === 'jde_ed_e1')
	      		substringIndex = 2	
	      	var description = this.jto_column_description
	      	if(join_side === 'from'){
	      		description = this.jfrom_column_description
	      		if(!description)
	      			description = []
	      		if(this.join_from_table === "BusinessObjectKeys" || this.join_from_table === "Business Object Keys"){
	      			substringIndex = 0
	      		}
			  }
			//   if(column_name.includes(".")){
			// 	column_name = column_name.split(".")[1]  
			//   }
	      	var description_text = edcGraphCommon.getColDescription(substringIndex,column_name,description,false)
	      	if(description_text)
	      		return column_name +" ("+description_text+")"
	      	return column_name
		},
		getJoinJson(jfrom,jto){
			var _this = this
			// 	let jfrom_index = _.findIndex(_this.new_jfrom_column_list, function(o) { return o.column_name ===jfrom })
			// let jto_index =_.findIndex(_this.new_jto_column_list, function(o) { return o.column_name === jto })
			return {
				jfrom: jfrom,
				from_table_name :_this.join_from_table,
				jfrom_table_type:_this.jfrom_table_type,
				jto: jto,
				type: _this.jtype,
				condition: _this.join_keys_list,
				jfrom_id: _this.join_from_table_id,
				to_table_name:_this.join_to_table,
				jto_table_type:_this.jto_table_type,
				jto_id: _this.join_to_table_id,
				jfrom_edc_id: _this.jfrom_edc_id,
				jto_edc_id:_this.jto_edc_id,
				join_relationship: _this.link_relationship,
				has_flattening_restriction: _this.has_flattening_restriction, //on UI we are showing opposite of it. Need to remove aflter demo
				hierarchyName: _this.newly_created_link.prop('hierarchy_name'),
				relationshipName: _this.join_from_table + "-" + _this.join_to_table,
				jfrom_datasource_index:_this.jfrom_datasource_index,
				jto_datasource_index:_this.jto_datasource_index
			}
			
		},
		add_join() {
			if(this.isReadOnly)
				return false
			
			let _this = this
															
			var link_found = false;
			var link = this.newly_created_link

			/* update link details/flattening restriction details or create new join only if user specified the any join between two tables

				this.jfrom && this.jto this condition will get apply at first time
				
				this.join_keys_list.length > 0 this condition will get apply when user want to update the relationship mapping or flattening updates*/
			if ((this.jfrom && this.jto) || this.join_keys_list.length > 0) {
				link.label(0, {
					attrs: {
						text: {
							text: this.link_relationship.split(":")[0]
						}
					}
				});
				link.label(1, {
					attrs: {
						text: {
							text: this.link_relationship.split(":")[1]
						}
					}
				});
				link.prop(
					"has_flattening_restriction",
					this.has_flattening_restriction
				);

				for (var i = 0; i < this.join_json.length; i++) {
					if (this.join_json[i][this.link_id]) {
						this.join_json[i][
							this.link_id
						].has_flattening_restriction = this.has_flattening_restriction;
						this.join_json[i][
							this.link_id
						].join_relationship = this.link_relationship;
						this.join_json[i][
							this.link_id
						].type = this.jtype;
						link_found = true;
						this.ChangeSaveStatus()
						break;
					}
				}
			}

			let jfrom_index = _.findIndex(this.new_jfrom_column_list, function(o) { return o.column_name ===_this.jfrom })
			let jto_index =_.findIndex(this.new_jto_column_list, function(o) { return o.column_name === _this.jto })
			
			if(jfrom_index < 0 || jto_index < 0)
				return

			var data = {
				jfrom: this.new_jfrom_column_list[jfrom_index].table_name,
				from_table_name :this.join_from_table,
				jfrom_table_type:this.jfrom_table_type,
				jto: this.new_jto_column_list[jto_index].table_name,
				type: this.jtype,
				condition: this.join_keys_list,
				jfrom_id: this.join_from_table_id,
				to_table_name:this.join_to_table,
				jto_table_type:this.jto_table_type,
				jto_id: this.join_to_table_id,
				jfrom_edc_id: this.jfrom_edc_id,
				jto_edc_id:this.jto_edc_id,
				join_relationship: this.link_relationship,
				has_flattening_restriction: this.has_flattening_restriction, //on UI we are showing opposite of it. Need to remove aflter demo
				hierarchyName: this.newly_created_link.prop('hierarchy_name'),
				relationshipName: this.join_from_table + "-" + this.join_to_table,
				jfrom_datasource_index:this.jfrom_datasource_index,
				jto_datasource_index:this.jto_datasource_index
			};
			
			
			if (!link_found) {
				var generate_dict = {};
				generate_dict[this.link_id] = data;
				this.join_json.push(generate_dict);
			}				

			if (this.jfrom && this.jto) {
				let conditionJson = {
								from_column: _this.new_jfrom_column_list[jfrom_index].column_name,
								from_table_name: _this.new_jfrom_column_list[jfrom_index].table_name,
								from_column_desc: _this.new_jfrom_column_list[jfrom_index].description,
								jfrom_datatype:_this.new_jfrom_column_list[jfrom_index].data_type,
								operator: _this.join_opeator,
								to_column: _this.new_jto_column_list[jto_index].column_name,
								to_table_name: _this.new_jto_column_list[jto_index].table_name,
								to_column_desc: _this.new_jto_column_list[jto_index].description,
								jfrom: _this.join_from_table,
								jto: _this.join_to_table,
								jto_datatype:_this.new_jto_column_list[jto_index].data_type,

							}
				if (this.update_join_button) {
					var start_index = this.update_join_button - 1;
					let oldCondtionJson = _.cloneDeep(this.join_keys_list[start_index])
					var number_of_elements_to_remove = 1;
					var l_id = this.link_id;
					var j_from = this.jfrom;
					var j_operator = this.join_opeator;
					var j_to = this.jto;
					var join_from_table = this.join_from_table;
					var join_to_table = this.join_to_table;
					var _obj = this.join_json.map(function (el) {
						if (el[l_id]) {
							el[l_id]["condition"].splice(
								start_index,
								number_of_elements_to_remove, 
								{
								from_column: _this.new_jfrom_column_list[jfrom_index].column_name,
								from_table_name: _this.new_jfrom_column_list[jfrom_index].table_name,
								from_column_desc: _this.new_jfrom_column_list[jfrom_index].description,
								jfrom_datatype:_this.new_jfrom_column_list[jfrom_index].data_type,
								operator: _this.join_opeator,
								to_column: _this.new_jto_column_list[jto_index].column_name,
								to_table_name: _this.new_jto_column_list[jto_index].table_name,
								to_column_desc: _this.new_jto_column_list[jto_index].description,
								jfrom: _this.join_from_table,
								jto: _this.join_to_table,
								jto_datatype:_this.new_jto_column_list[jto_index].data_type,

							}
							);
						}
					});
					// old value and new value is litle confusing here but its corrent, because when user going to undo or redo we are swapping the values
					this.pushForUndo('update_join_condition',{
						"link_id":this.link_id,'old_value':conditionJson,'new_value':oldCondtionJson,'conditionIndex':start_index,
						'link':this.zippedLink,'isForJoin':true
					})
				} else {
					for (var i = 0; i < this.join_json.length; i++) {
						if (
							this.join_json[i][this.link_id] &&
							!this.join_json[i][this.link_id]["condition"].some(
								elem =>
								this.jfrom === elem.from_column &&
								this.jto === elem.to_column &&
								this.join_opeator === elem.operator
							)
						) {
							// let _this = this																
							// let jfrom_index = _.findIndex(this.new_jfrom_column_list, function(o) { return o.description.contains(_this.jfrom); })
							// let jto_index =_.findIndex(this.new_jto_column_list, function(o) { return o.description.contains(_this.jto); })
							// this.join_json[i][this.link_id]["condition"].push({
								// from_column: this.join_from_table + "." + this.jfrom,
							// 	from_column: this.new_jfrom_column_list[jfrom_index].column_name,
							// 	from_column_desc:this.new_jfrom_column_list[jfrom_index].description,
							// 	operator: this.join_opeator,
							// 	jfrom_datatype:this.new_jfrom_column_list[jfrom_index].data_type,
							// 	to_column: this.new_jto_column_list[jto_index].column_name,
							// 	to_column_desc: this.new_jto_column_list[jto_index].description,
							// 	jfrom: this.new_jfrom_column_list[jfrom_index].table_name,
							// 	jto: this.new_jto_column_list[jto_index].table_name,
							// 	jto_datatype:this.new_jto_column_list[jto_index].data_type,
							// });
							this.join_json[i][this.link_id]["condition"].push(conditionJson);
							this.pushForUndo('add_join_condition',{
								"link_id":this.link_id,'conditionJson':conditionJson,'conditionIndex':this.join_json[i][this.link_id]["condition"].length-1,
								'link':this.zippedLink,'isForJoin':true
							})
							this.join_json_for_link = this.join_json[i][
								this.link_id
							].condition;
							break;
						}
					}
				}
			}
			this.copyJoinJson = _.cloneDeep(this.join_json)
			this.jfrom = "";
			this.jto = "";
			this.join_opeator = "_eq_";
			this.update_join_button = false;
			this.ChangeSaveStatus();
		},
		update_join(j_from, j_cond, j_to, index) {
			if(this.isReadOnly)
				return false
			this.jfrom = j_from;
			this.jto = j_to;
			(this.join_opeator = j_cond), (this.update_join_button = index + 1);
		},
		delete_join(index) {
			if(this.isReadOnly)
				return false
			for (var i = 0; i < this.join_json.length; i++) {
				if (this.join_json[i][this.link_id]) {
					let deleted_join = this.join_json[i][this.link_id]["condition"].splice(index, 1);
					this.pushForUndo('remove_join_condition',{
						"link_id":this.link_id,'conditionJson':deleted_join[0],'conditionIndex':index,
						'link':this.zippedLink,'isForJoin':true
					})
					break;
				}
			}
			this.ChangeSaveStatus();
		},
		delete_unique_identifier(index) {
			this.columns_for_unique_identifiers.splice(index, 1);
			this.ChangeSaveStatus();
		},
		dragitem(event) {
			event.dataTransfer.setData("table_name", event.target.innerText);
		},
		showFilterRow(cell,openFilter) {
			this.env_id = this.$session.get("selected_env").id;
			this.cell_id = cell.id;
			this.filter_for = 'cell'
			this.filter_column_list =[]
			var table_name = cell.prop('table_name')
			let table_id = cell.prop('object_id')
			let datasource_index = cell.prop('datasource_index')
			this.filtertablename = table_name;
			var table_type = cell.prop("table_type");
			var _self = this;
			var kwargs = _self.getDatasourceObj(datasource_index)
			_.assign(kwargs, _self.getCommonDetailsForColumnFetch(table_name,table_type))
	    getRevisedColumnsWithDescription(_self,kwargs).then(tableColumnResponse => {
						_self.filter_column_list = _self.filter_column_list.concat(tableColumnResponse)
						if (!_self.filter_json[cell.id]) {
							var obj = _.cloneDeep(_self.filter_column_tbl_obj);
							obj["id"] = this.get_new_id();
							_self.filter_rows_list = [];
							_self.main_filterUI = []
							_self.secondary_filterUI = {groupList:[]}
							_self.filter_rows_list.push(obj);
							_self.filter_json[_self.cell_id] = {};
							_self.filter_json[_self.cell_id]["filter_rows_list"] =
								_self.filter_rows_list;
						} else {
							_self.main_filterUI = []
							_self.secondary_filterUI = {groupList:[]}
							_self.filter_rows_list =
								_self.filter_json[_self.cell_id]["filter_rows_list"];
						}
						if(openFilter)
							_self.openFilterPanel(tableColumnResponse)

					}).catch();
		},
		showLinkFilterRow(link) {
			let _self = this;
			this.cell_id = link.id;
			this.filter_for = 'link'
			/**
			 * If we dont have link id in link_filter_json
			 */
			 _self.main_filterUI = []
			_self.secondary_filterUI = {groupList:[]}
			if (!_self.link_filter_json[_self.cell_id]) {
				var obj = _.cloneDeep(_self.filter_column_tbl_obj);
				obj["id"] = this.get_new_id();
				_self.filter_rows_list = [];
				_self.filter_rows_list.push(obj);
				_self.link_filter_json[_self.cell_id] = {};
				_self.link_filter_json[_self.cell_id]["filter_rows_list"] =
					_self.filter_rows_list;
			} else {
				_self.filter_rows_list =
					_self.link_filter_json[_self.cell_id]["filter_rows_list"];
			}
			_self.openFilterPanel()
		},
		clearAllIdentifier() {
			this.columns_for_unique_identifiers = [];
			this.dbl_click_tbl_items.map(item => {
				item.forunique = false;
			});
			this.hideDetails();
		},
		add_new_filter_row() {
			var current_filter_length = this.filter_rows_list.length;
			if (current_filter_length > 0) {
				if (!this.filter_rows_list[current_filter_length - 1]["operation"])
					this.filter_rows_list[current_filter_length - 1]["operation"] = false;
			}
			var obj = _.cloneDeep(this.filter_column_tbl_obj);
			obj["id"] = this.get_new_id();
			this.filter_rows_list.push(obj);
		},
		get_new_id() {
			return ++this.auto_increment_id;
		},
		delete_filter_row(idx) {
			var current_filter_length = this.filter_rows_list.length;
			if (current_filter_length > 1 && current_filter_length - 1 === idx)
				delete this.filter_rows_list[idx - 1]["operation"];
			this.filter_rows_list.splice(idx, 1);
			if (this.filter_rows_list.length === 0) {
				var obj = _.cloneDeep(this.filter_column_tbl_obj);
				this.filter_rows_list.push(obj);
			}
			this.ChangeSaveStatus();
		},
		getRevisedFilter(filterToRevise){
			var revised_filter_data =  _.cloneDeep(filterToRevise);
			Object.keys(revised_filter_data).forEach(function (key) {
				if (
					revised_filter_data[key].filter_rows_list.length === 1 &&
					revised_filter_data[key].filter_rows_list[0].column_name === ""
				) {
					delete revised_filter_data[key];
				}
			});
			return revised_filter_data
		},

		GetData() {
			// 
			var client_id = this.$session.get("client_id");
			var user = this.$session.get("email");
			let data = this.graphData
			var revised_filter_data = this.getRevisedFilter(this.filter_json)
			var revLinkFilterJson = this.getRevisedFilter(this.link_filter_json)
			// 
			var business_data = {
				joins: this.join_json,
				filters: revised_filter_data,
				link_filters:revLinkFilterJson,
			};
			var graphData = {
				graph_data: data,
				business_view_name: this.businessViewName,
				client_id: client_id,
				created_by: user,
				env_id: this.env_id,
				id: this.business_view_id,
				selected_object_type_code:"T",
				columns_for_view:this.selectedColumnList,
				show_distinct_records:this.showDistinctRecords,
				business_data: business_data,
				unique_identifier_keys: this.columns_for_unique_identifiers,
				table_list: this.dragged_tbl_list,
				auto_id: this.auto_increment_id,
				dragged_tbl_list_with_its_type:this.dragged_tbl_list_with_its_type,
				isAug2020ReleasedUI:true,
				merge_tables_detail:edcMergeObj.getMergeDetails(this.graphData)
			};
			return graphData;
		},
		Validatedatamapobj() {
			let _this = this;
			if(_this.validateProgress){
				alert(VALIDATION_IN_RPOCESS_MESSAGE)
				return false
			}
			_this.bographdata = {};
			_this.errorListCount = 0;
			var data = this.graphData
			var graphData = this.GetData();
			_this.validateProgress = true;
			_this.setErrorOnBO(false)
			// this.resetCriteriaValidation();
			var ValidateProcessDefinitionUrl =
				config.VALIDATION_URL + VALIDATE_DATA_MAP + "true";
			postToServer(this, ValidateProcessDefinitionUrl, graphData)
				.then(validation_response => {
					//
					_this.validation_response = validation_response
					_this.bographdata = data
					_this.validateProgress = false;
				})
				.catch(error_response => {
					_this.validateProgress = false;
					if (error_response) {
						this.snackbar = true;
						this.colorValue = "error";
						this.snackbartext = error_response;
					} else {
						this.snackbar = true;
						this.snackbartext = "Something went wrong.Try Again";
						this.colorValue = "error";
					}
				});
		},
		setCriteriaError(filters) {
			let _this = this;
			let tableList = Object.keys(filters);
			//
			tableList.map(tableObj => {
				let rowIdArray = Object.keys(filters[tableObj]);
				rowIdArray.map(rowId => {
					let columnArray = Object.keys(filters[tableObj][rowId]);
					let errorObject = {
						columnName: "",
						message: ""
					};
					let rowIndex = rowId;
					/**
					 * @description for $set see https://vuejs.org/v2/guide/list.html#Caveats
					 */
					_this.$set(
						_this.filter_json[tableObj]["filter_rows_list"][rowIndex],
						"error_list", {}
					);
					columnArray.map(columnName => {
						let message = filters[tableObj][rowId][columnName];
						_this.filter_json[tableObj]["filter_rows_list"][
							rowIndex
						].error_list[columnName] = message;
						_this.errorListCount += 1;
					});
					// _this.filter_json[tableObj][rowIndex].error_list.name ="";
				});
			});
		},
		resetCriteriaValidation() {
			let _this = this;
			let tableList = Object.keys(_this.filter_json);
			_this.table_list_error = "";
			tableList &&
				tableList.map(table => {
					_this.filter_json[table] &&
						_this.filter_json[table]["filter_rows_list"] &&
						_this.filter_json[table]["filter_rows_list"].map(row => {
							_this.$set(row, "error_list", {});
						});
				});
		},
		toolbarSaveClicked(){
			let confirmFn = function () {
	        	this.$parent.AutoSaveBusinessViewGraph(true)
	        }
	        let obj = {
	          title: 'Save',
	          message: 'All changes will get saved, Proceed?',
	          type: 'info',
	          useConfirmBtn: true,
	          customConfirmBtnText:'Save',
	          customCloseBtnText:'Cancel',
	          onConfirm: confirmFn,
	          showXclose:true
	        }
	        this.$refs.simplert.openSimplert(obj)
		},
		showClosePopUp(){
			var _this = this
			if(this.savedstatus === 'edited'){
				let confirmFn = function () {
		        	_this.AutoSaveBusinessViewGraph(true,true)
		        }
		        let closeFn = function () {
		        	_this.closeTab()
		        }
		        let obj = {
		          title: 'Close Tab',
		          message: 'Some changes are not saved, Proceed?',
		          type: 'info',
		          useConfirmBtn: true,
		          customConfirmBtnText:'Save & Close',
		          customCloseBtnText:'Close Anyway',
		          onConfirm: confirmFn,
		          onClose:closeFn,
		          showXclose:true
		        }
		        this.$refs.simplert.openSimplert(obj)
			}
			else{
				let confirmFn = function () {
		        	this.$parent.closeTab()
		        }
		        let obj = {
		          title: 'Close Tab',
		          message: 'Tab will get close, Proceed?',
		          type: 'info',
		          useConfirmBtn: true,
		          customConfirmBtnText:'Close',
		          customCloseBtnText:'Cancel',
		          onConfirm: confirmFn,
		          showXclose:true
		        }
		        this.$refs.simplert.openSimplert(obj)
			}
		},
		closeTab(){
			window.top.close()
		},
		AutoSaveBusinessViewGraph(isSaveClicked,closeTabAfterSave){
			var _this = this
			if(!this.isObjectFullyLoaded || this.isReadOnly)
				return
			
			// If autoSave is off, then save only when user click on save button
			if(!isSaveClicked && !this.isAutoSave)
				return
			
			this.env_id = this.$session.get("selected_env").id;
			this.env_name = this.$session.get("selected_env").name;
			if (this.savedstatus != "saved" && !this.is_request_in_process) {
				let data_send_backend = [];
				var graphData = this.GetData();
				var client_id = this.$session.get("client_id");
				var user = this.$session.get("email");
				var graphData = {
					object_json: graphData,
					object_type_code: "BV",
					object_desc: "",
					erp_code : this.erp_code,
					erp_version : this.erp_version,
					object_name: this.businessViewName,
					description: this.bo_description,
					object_revision: this.object_revision,
					client_id: client_id,
					added_by: user,
					env_id: this.env_id,
					env_name: this.env_name,
					object_id: this.business_view_id,
					product:this.productName
				};
				this.oldDMName = this.businessViewName
				this.is_request_in_process=true
				postToServer(
						this,
						config.REPOSITORY_API_URL + ADD_GRAPH_DATA,
						graphData
					)
					.then(response => {
						this.is_request_in_process=false
						if (response) {
							var res = response;
							this.is_error_occured = false;
							this.business_view_id = response.object_id;
							this.businessViewName = response.object_name;
							this.oldDMName = response.object_name;
							this.object_revision = response.object_revision;
							this.savedstatus = "saved";
						}
						if(closeTabAfterSave)
		      		_this.closeTab()
					})
					.catch(error_response => {
						_this.is_request_in_process = false
						_this.is_error_occured = true;
						_this.savedstatus = "Error in the saving data into the databse.";
					});
			}
		},
		RedesignGraph(env_has_allow_create,create_new_version) {
			if (this.$route.params.create_new_version || create_new_version) {
				var create_new_version = true;
			} else {
				var create_new_version = false;
			}

			// from object management page user click on create new version but env might not have create new version rights. So for that purpose added below check.
			if(env_has_allow_create === 'No')
				create_new_version = false

			if(create_new_version)
				this.isReadOnly = false

			this.env_id = this.env_id;
			var data = {
				object_id: this.$route.params.business_view_id,
				object_revision: this.$route.params.object_revision,
				object_type_code: this.$route.params.object_type_code ? this.$route.params.object_type_code : "BV",
				create_new_version: create_new_version,
				env_id: this.$route.params.env_id,
				env_name: this.$route.params.env_name
			};
			var _this = this;

			_this.tableListLoader = true;
			postToServer(
					this,
					config.REPOSITORY_API_URL + GET_PROCESS_GRAPH_DATA,
					data
				)
				.then(response => {
					var res = response;					
					// 
					// this.env_id = res.object_json.env_id
					this.auto_increment_id = res.id ? res.id : 0;
					this.business_view_id = res.object_id;
					this.object_revision = res.object_revision;
					this.businessViewName = res.object_name;
					this.oldDMName = res.object_name;
					console.log('res => ',res)
					this.manageServerResponse(res);
				})
				.catch(error_response => {
					_this.tableListLoader = false;
					if (error_response) {
						this.snackbar = true;
						this.colorValue = "error";
						this.snackbartext = error_response;
						this.loader = null;
					} else {
						this.snackbar = true;
						this.colorValue = "error";
						this.snackbartext = SERVER_ERROR;
					}
				});

			// }
		},

		CopyGraph() {
			this.env_id = this.env_id;
			var data = {
				object_id: this.$route.params.business_view_id,
				object_type_code: this.$route.params.object_type_code,
				object_revision: this.$route.params.object_revision,
				env_id: this.$route.params.env_id
			};
			var _this = this;
			_this.tableListLoader = true;
			postToServer(
					this,
					config.REPOSITORY_API_URL + GET_PROCESS_GRAPH_DATA,
					data
				)
				.then(response => {
					var res = response;
					this.auto_increment_id = res.id ? res.id : 0;
					this.businessViewName = "Copy of " + res.object_name;
					this.manageServerResponse(res)
				})
				.catch(error_response => {
					_this.tableListLoader = false;
					if (error_response) {
						this.snackbar = true;
						this.colorValue = "error";
						this.snackbartext = error_response;
						this.loader = null;
					} else {
						this.snackbar = true;
						this.colorValue = "error";
						this.snackbartext = SERVER_ERROR;
					}
				});

			// }
		},
		manageServerResponse(res){
				this.startInitialSaveTimer(8000)
				if(this.$session.get('is_read_only')){
		          this.isReadOnly = true
				}
				this.selectedColumnList = res.object_json.columns_for_view
		        if(this.dragged_tbl_list.length || res.object_json.dragged_tbl_list_with_its_type.length)
		       	{
		       		this.dataForGraphRedesign =  {"cells":res.object_json.graph_data.cells,"uniqueIdentifierId":res.object_json.business_data.unique_identifier_id,"isAug2020ReleasedUI":res.object_json.isAug2020ReleasedUI,'tableFilters':res.object_json.business_data.filters}

					this.join_json = res.object_json.business_data.joins;
					this.copyJoinJson = _.cloneDeep(this.join_json)
					this.filter_json = res.object_json.business_data.filters;
					this.link_filter_json = res.object_json.business_data.link_filters || {};
					this.columns_for_unique_identifiers =
						res.object_json.unique_identifier_keys;
					console.log('this.columns_for_unique_identifiers',this.columns_for_unique_identifiers)
					this.dragged_tbl_list = res.object_json.table_list;
					if(res.object_json.dragged_tbl_list_with_its_type)
						this.dragged_tbl_list_with_its_type = res.object_json.dragged_tbl_list_with_its_type
		       	}
				
					// 
					this.bo_description = res.description
					this.Get_Tables();

					var dragged_tbl_list_with_type = [];
					for (var i = res.object_json.table_list.length - 1; i >= 0; i--) {
						dragged_tbl_list_with_type.push({
							table_name: res.object_json.table_list[i],
							table_type: "table"
						});
					}

		},
		close_join_dialog(closeOnly) {
			if(!closeOnly)
				this.add_join();

			// check is any join is specified or not on that link. If not then trigger remove link evnet to main graph
			if(this.link_id){
				let current_link_join = _.find(this.join_json,this.link_id)
				if(!current_link_join || !current_link_join[this.link_id]['condition'] || !current_link_join[this.link_id]['condition'].length)
					this.cellToRemove ={
						'cell_id':this.link_id,
						'type':'link'
					}
			
				if(current_link_join && current_link_join[this.link_id].type)
					current_link_join[this.link_id].type = this.jtype
			}
			this.new_jfrom_column_list = []
			this.new_jto_column_list = []
			this.jtype = "Inner Join"
			this.link_dialog = false;
			this.link_id = ''
		},
		perform_collapse(action) {
			var main_holder = document.getElementById("main_holder")
			var main_container =document.getElementById("main_container")
			var lens = document.getElementById("lens")
			var lens_width = lens.style.width
			if(action === 'close'){
				
				main_holder.style.width="98%";
				lens_width = main_container.clientWidth * this.small_paper_scale
			}
			else{
				
				main_holder.style.width="80%";         	
				lens_width =main_container.clientWidth * this.small_paper_scale 
			}
			lens.style.width = Math.round(lens_width)+"px"
		},
		close_date_format_popup() {
			this.dateformat_dialog = false;
		},
		showDescription(){
	    	this.vueEditorObj = {}
	    	this.vueEditorObj['sourcepage'] ='bo'
	    	this.vueEditorObj['description'] = _.cloneDeep(this.bo_description)
	    	this.descriptionPanel = true
	    },
	    DescriptionChange(bo_description){
	    	this.bo_description = bo_description
	    	this.ChangeSaveStatus()
	    },
	    createNewVersion:function(record){
            let _this = this;
            var inputJson =  {"object_id": this.business_view_id, "object_revision": this.object_revision, "env_id": this.$session.get('selected_env').id}

            postToServer(this, config.PUBLISHER_URL + '/object_published_in_multiple_env', inputJson).then(response => {
                    if(response){
                        if(response.status == 'PENDING_APPROVAL'){
                            alert("Object is in Approval pending state, Can not create New version")
                        } else {
                            this.ShowConfirmNewVersion(inputJson)
                        }
                    } else {
                       this.errorMsg = response
                    }
                }).catch(ProcessDocError => {
                    if(ProcessDocError){
                        this.loader = null 
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.tableList.rows = [];
                        this.tableList.total_count =0; 
                        this.snackbartext = ProcessDocError;
                    }
                    else {
                        this.snackbar = true
                        this.colorValue = 'error'
                        this.snackbartext = SERVER_ERROR;
                    }
                });
		},

        ShowConfirmNewVersion(object){
		var _this = this
        let confirmFn = function () {
        _this.SendObjectForNewVerion(object)
        }
        let obj = {
          title: 'Create New Version',
          message: 'This will create new version of Object and get replaced with Current version of Object, Proceed?',
          type: 'info',
          useConfirmBtn: true,
          onConfirm: confirmFn
        }
        this.$refs.simplert.openSimplert(obj)
      },
      SendObjectForNewVerion(object){   
       	this.RedesignGraph('Yes',true)
      },
      ShowObjDetails(){
      	this.reqInputData = {}
      	var data = {"object_id": this.business_view_id, "object_revision": this.object_revision}
      	this.reqInputData = data
      	this.showObjectDetails = true
      },
      closeDetailPopup(){
      	this.showObjectDetails = false
      },
	  setErrorOnBO(isHeighlight,cell_id,message){
			this.validationGraphObj = {
				'isHeighlight':isHeighlight,
				'cell_id':cell_id,
				'message':message,
			}	  	
		},
		JoinAcceleration(jfrom){
			if(!jfrom)
				return
			var start_index = 0
			if(this.acceleratorObj.acceleration_type ==='none')
				return
			if(this.acceleratorObj.acceleration_type === "jde_ed_e1")
				start_index = 2
			// if condition for acceleration type jde/e-business
			// code for jde acceleration
			var jfrom_alias = jfrom.substring(start_index,jfrom.length)
			var jto = _.find(this.jto_column_list,function(col){
				let obj = col.column_name
				if(obj && obj.substring(start_index,obj.length) === jfrom_alias)
					return obj
			})
			if(jto)
				this.jto = jto.column_name
		},
    	loadTableDescription(table_list){
    		// set timeout for load acceleration details
    		let _this = this
    		setTimeout(()=>{
    			getTableDescriptions(this,table_list,this.env_id).then(response=>{
    				_this.tableDescriptionList = response
    			}).catch(error=>{

    			})
    		},4000)
    	},
    	autoArrangeBusinessObject(){
    		let _this = this
    		this.autoArrangeGraph = true
    		// reset the auto arrange for next call
    		setTimeout(()=>{
    			_this.autoArrangeGraph = false
    		},200)
    	},
    	startInitialSaveTimer(timing){
	      	let _this = this
	      	// over come issue with load object before loadding anything, I am writing set timeout for few seconds
			setTimeout(()=>{
				_this.isObjectFullyLoaded = true
			},timing) 
	    },
	    getDatasourceObj(datasource_index){
	    	// in this function, we will return the datasource details which return by getTablesByDVEnv.
	    	var _this = this
	    	if(!_this.tablelistpanelproperties.available_datasource_list[datasource_index])
	    	{
	    		alert("datasource details not found for index "+datasource_index)
	    		return {}
	    	}
		    return	_.cloneDeep(_this.tablelistpanelproperties.available_datasource_list[datasource_index])
	    },
	    getCommonDetailsForColumnFetch(table_name,table_type){
	    	var _this = this
	    	return {"table_column_list":_this.table_column_list, "env_id":_this.env_id, "table_name":table_name, "table_type": table_type, "need_column_db_details":true}
	    },
	    getDataSourcesDetails(){
	    	var _this = this
	    	var data = {'client_id':this.$session.get('client_id'),
    		'env_id':this.env_id}
    		var url = config.ENVIRONMENT_API_URL + '/get_datasource_details_with_env_id_for_dv_public'
    		postToServer(_this, url ,data).then(response => {
    			if(response){
    				if(response.all_datasource_details){
    					_this.ds_info = []
    					for(let i=0;i<response.all_datasource_details.length;i++){
				        	let generated_ds = _this.generateDS(response.all_datasource_details[i])
				      		_this.ds_info.push(generated_ds)
				      	}
    				}
    			}
    		}).catch(EngineError => {

    		})
	    },
	    generateDS(unformattedDS){
	    	let formatedDS = {"business_data":unformattedDS.business_data,
				"engine_data":unformattedDS.business_data,"db_type":unformattedDS.business_data.database_type}
			if(unformattedDS.accelerator_details){
				formatedDS["acceleration_type"]=unformattedDS.accelerator_details.accelerator_type
				formatedDS["control_data"]=unformattedDS.accelerator_details.control_datasource
				formatedDS["object_librarian_data"]=unformattedDS.accelerator_details.object_librarian_datasource
				formatedDS["dictionary_data"]=unformattedDS.accelerator_details.dictionary_datasource
				
			}
	    	return formatedDS
	    },
		getDatasourceName(index){
			if(!this.ds_info || !this.ds_info.length||!this.ds_info[index]||!this.ds_info[index].business_data)
				return ''
			return this.ds_info[index].business_data.datasource_name 
		},
		pushForUndo(action,data){
			if(action === 'unmerge_table'){
	    		edcMergeObj.unMergeTable(this,data)
	    		return
	    	}
	    	if(action === 'bv_name_changed'){
	    		data['action_to_take'] = action
	    		data['user_action'] =action
				this.reverseData(data)
	    	} else if(action === 'add_element'){
	    		data['action_to_take'] = 'remove_element'
	    		data['user_action'] =action
	    	} else if(action === 'remove_table'){
	    		data['action_to_take'] = 'add_element'
	    		data['user_action'] = 'remove_element'
	    	} else if(action ==='add_join_condition'){
	    		data['action_to_take'] = 'remove_join_condition'
	    		data['user_action'] = action
	    	} else if(action ==='remove_join_condition'){
	    		data['action_to_take'] = 'add_join_condition'
	    		data['user_action'] = action
	    	} else if(action === 'update_join_condition'){
	    		data['action_to_take'] = action
	    		data['user_action'] = action
	    	} else if(action === 'filter_change'){
	    		data['action_to_take'] = action
	    		data['user_action'] = action
	    	} else if(action === 'add_selection'){
	    		data['action_to_take'] = 'remove_selection'
	    		data['user_action'] = action
			} else if(action === 'remove_selection'){
	    		data['action_to_take'] = 'add_selection'
	    		data['user_action'] = action
			}
	    	if(data.user_action)
	    		this.undoStack.push(data)
	    
	    },
	    reverseData(data){
	    	let oldValue = data['old_value']
	    	data['old_value'] = data['new_value']
	    	data['new_value'] = oldValue
	    	return data
	    },
	    keyupHandler(event){
			if (event.ctrlKey && event.code === 'KeyZ') {
      			this.undoHandler()
    		}
    		else if (event.ctrlKey && event.code === 'KeyY') {
      			this.redoHandler()
    		}
		},
		undoHandler(){
			let _this = this
			if(!_this.undoStack.length)
				return
			let undoData = _this.undoStack.pop()
			_this.handlerUndoRedo(undoData,'undo')
			let redoData = _.cloneDeep(undoData)
			redoData['action_to_take'] = undoData.user_action
			redoData['user_action'] = undoData.action_to_take
			if(redoData.action_to_take === 'bv_name_changed'){
				this.reverseData(redoData)
			} else if(redoData.action_to_take === 'update_join_condition')
				this.reverseData(redoData)
			else if(redoData.action_to_take === 'filter_change')
				this.reverseData(redoData)
			_this.redoStack.push(redoData)
		},
		redoHandler(){
			let _this = this
			let redoData = _this.redoStack.pop()
			let undoData =  _.cloneDeep(redoData)
			undoData['action_to_take'] = redoData.user_action
			undoData['user_action'] = redoData.action_to_take
			if(undoData.action_to_take === 'bv_name_changed'){
				this.reverseData(undoData)
			} else if(undoData.action_to_take === 'update_join_condition')
				this.reverseData(undoData)
			else if(undoData.action_to_take === 'filter_change')
				this.reverseData(undoData)

			_this.undoStack.push(undoData)
			_this.handlerUndoRedo(redoData,'redo')
		},
		handlerUndoRedo(data,action){
			if(!data ||!data.action_to_take)
				return
			if(!data.isForJoin || (this.link_dialog && data.link_id !=this.link_id))
				// we have close join dialog whenever user press ctrl+z or ctrl+y only if that action is not related to currently open join dialog
				this.close_join_dialog(true)
			
			this.closeFilter('close')
			
			var _this = this
			let undoredoMsg = ''
			if(data.action_to_take === 'bv_name_changed'){
				undoredoMsg = action +": Datamap name changed from "+data.old_value + " to "+data.new_value
				this.businessViewName = data.new_value
			} else if (data.action_to_take === 'remove_element'){
				undoredoMsg = action + ": Table "+data.element.table_name+" removed"
				this.stepper = 1
				this.cellToRemove ={
					'cell_id':data.element.id
				}
			} else if(data.action_to_take === 'add_element'){
				undoredoMsg = action + ": Table "+data.element.table_name+" added"
				data.element['edc_type'] = 'bo_table'
				data.element.relatedLinks = data.relatedLinks
				this.newCell = data.element
				this.stepper = 1
				this.addMoreLinkDetails(data)
			}  else if(data.action_to_take === 'remove_selection'){
				undoredoMsg = action + ": Removed "+data.column.column_display_name+" column from selected columns list"
				let index = _this.selectedColumnList.findIndex(x => x.column_display_name === data.column.column_display_name)
				_this.selectedColumnList.splice(index, 1)
				this.stepper = 2
			} else if(data.action_to_take === 'add_selection'){
				undoredoMsg = action + ": Added "+data.column.column_display_name+" in selected columns list"
				_this.selectedColumnList.push(data.column)
				this.stepper = 2
			} else if(data.action_to_take === 'add_link'){
				data['edc_type'] = 'bus_link'
				data['relatedLinks'] = [data.link]
 				this.newCell = data
				this.addMoreLinkDetails(data)
				this.stepper = 1
				undoredoMsg = action+': Relationship added'
				if(data.relatedJoins && data.relatedJoins.length && data.relatedJoins[0][data.link.id]){
					undoredoMsg = undoredoMsg+' between '+data.relatedJoins[0][data.link.id].relationshipName
				}
			} else if(data.action_to_take === 'remove_link'){
				this.cellToRemove ={"link_id":data.link.id}
				undoredoMsg = action+': Relationship removed'
				this.stepper = 1
				if(data.relatedJoins && data.relatedJoins.length && data.relatedJoins[0][data.link.id]){
					undoredoMsg = undoredoMsg+' between '+data.relatedJoins[0][data.link.id].relationshipName
				}
			} else if(data.action_to_take && data.isForJoin){
				// we will handle all join condition redo-undo actions in getEventDetails method
				undoredoMsg = action+": Condtion "
				this.stepper = 1
				if(data.conditionJson || data.new_value){
					if(data.action_to_take === "add_join_condition"){
						undoredoMsg = undoredoMsg+" added in relationship "+data.conditionJson.jfrom+" - "+data.conditionJson.jto
					}else if(data.action_to_take === "remove_join_condition"){
						undoredoMsg = undoredoMsg+" removed from relationship "+data.conditionJson.jfrom+" - "+data.conditionJson.jto
					}else{
						undoredoMsg = undoredoMsg+" updated in relationship "+data.new_value.jfrom+" - "+data.new_value.jto
					}
				}
			} else if(data.action_to_take === 'filter_change'){
				undoredoMsg =action+": Filter modified for "
				if(data.filter_for === 'cell')
					undoredoMsg = undoredoMsg + " table"
				else
					undoredoMsg = undoredoMsg + " relationship"
				this.manageUndoRedoFilters(data)
			}

			if(undoredoMsg)
 				this.setRedoUndoMsgTimeout(undoredoMsg)
		},
		addMoreLinkDetails(data){
			if(data.relatedJoins && data.relatedJoins.length){
				this.join_json = _.merge(this.join_json,data.relatedJoins)
				this.copyJoinJson = _.cloneDeep(this.join_json)
			}
		},
		setRedoUndoMsgTimeout(message){
			var _this = this
			_this.redoundoMsg = message
			_this.redoundoTimer = null
			_this.redoundoTimer = setTimeout(()=>{
				_this.redoundoMsg = ''
			},8000)
		},
		manageJoinsRedoUndoActions(joinData,linkJoinIndex=-1){
			if(linkJoinIndex<0){
				// It means we have added a new link. Now we have to add join json into the link.
				let data = this.getJoinJson(joinData.conditionJson.from_table_name,joinData.conditionJson.from_table_name)
				data['condition'] = [joinData.conditionJson]
				this.join_json[this.join_json.length] = {}
				this.join_json[this.join_json.length-1][joinData.link_id] = data
				this.join_keys_list = this.join_json[this.join_json.length-1][joinData.link_id].condition;
				this.join_json_for_link = this.join_json[this.join_json.length-1][joinData.link_id].condition;
			}
			else{
				let joinObj = this.join_json[linkJoinIndex][joinData.link_id]
				if(joinData["action_to_take"] === "remove_join_condition"){
					joinObj.condition.splice(joinData.conditionIndex,1)
				}
				else if(joinData["action_to_take"] === "add_join_condition"){
					joinObj.condition.splice(joinData.conditionIndex,0,joinData.conditionJson)
				} else if(joinData["action_to_take"] === "update_join_condition")
					joinObj.condition.splice(joinData.conditionIndex,1,joinData.new_value)
			}
			this.copyJoinJson = _.cloneDeep(this.join_json)
		},
		manageUndoRedoFilters(data){
			// we have to close filter panel if that already open.
			this.closeFilter('close')
			// then we will assign data from the json to the page variables which we use for the filter
			this.filter_for = data.filter_for
			this.cell_id = data.cell_id
			this.filter_rows_list = data.new_value
			// now we will save this json in the respective filter json. For that purpose we will again call the closeFilter function with save action
			this.closeFilter('save',true)
		},
	},
}
</script>
<style>
/* port styling */
.available-magnet {
  fill: yellow;
}
/* element styling */
.available-cell rect {
  stroke-dasharray: 5, 2;
}

div#main_container {
  height: 550px;
  width: 100%;
  border: 0px dotted #000;
  overflow: scroll;
  left: 10px;
}

.lens {
  position: absolute;
  border: 1px solid #2a9393;
  transform-origin: top left;
  transform: scale(1);
}

.disabled {
  box-shadow: none;
  opacity: 0.5;
}

.label-margin label {
  margin-top: 7%;
}
.customCheckbox >>> label{
  top: 5px !important;
}
#flatteningdiv .v-label {
  margin-bottom:0.0rem !important;
}

.JoinLeftSide {
	margin-right:10px !important;
}
.JoinRightSide {
	margin-left:10px !important;
}

.EDC-Toolbar >>> .v-sheet {
  border-radius: 0px !important;
}
</style>
<style scoped>
/* radio label styling */
.radioClass >>> label{
    top: 5px !important;
	/* margin: 7px !important; */
} 

.EDC-VCards {
  margin: 0px !important;
  height: 400px !important;
}

.EDC-Custom-Toolbar {
    padding:0px !important;
}

.EDC-list-for-columns {
	max-height:412px !important;
	min-height:412px !important;
}

.EDC-Toolbar >>> .v-sheet {
  border-radius: 0px !important;
}

</style>
